import { useContext, useState } from "react";
import "./dialog-styles.css";
import { StoryContext } from "../views/Game.tsx";
import {
  Cross2Icon,
  DrawingPinFilledIcon,
  DrawingPinIcon,
} from "@radix-ui/react-icons";
import * as Popover from "@radix-ui/react-popover";
import { Box } from "@radix-ui/themes";

function StoryDialog() {
  const [storyContext] = useContext(StoryContext);
  const [open, setOpen] = useState(true);

  return (
    <Box style={{ position: "fixed", left: "7px", top: "70px" }}>
      <Popover.Root
        open={open}
        onOpenChange={() => {
          setOpen(!open);
        }}
      >
        {storyContext.storyDialogOpen && (
          <Popover.Trigger asChild>
            <button className="IconButton" aria-label="Story available">
              <DrawingPinFilledIcon width={35} height={50} />
            </button>
          </Popover.Trigger>
        )}
        {!storyContext.storyDialogOpen && (
          <button className="IconButtonDisabled" aria-label="...">
            <DrawingPinIcon width={35} height={50} />
          </button>
        )}
        {storyContext.storyDialogOpen && (
          <Popover.Portal>
            <Popover.Content className="PopoverContent" sideOffset={5}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                <p className="Text" style={{ marginBottom: 10 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    {storyContext.storyTitle}
                  </span>
                  <span
                    style={{
                      display: "block",
                      height: "200px",
                      overflowY: "scroll",
                    }}
                  >
                    {storyContext.storyText}
                  </span>
                </p>
              </div>
              <Popover.Close className="PopoverClose" aria-label="Close">
                <Cross2Icon />
              </Popover.Close>
              <Popover.Arrow className="PopoverArrow" />
            </Popover.Content>
          </Popover.Portal>
        )}
      </Popover.Root>
    </Box>
  );
}

export default StoryDialog;
