import { useContext } from "react";
import { ZoneContext } from "../views/Game.tsx";
import { Box, Callout } from "@radix-ui/themes";
import { GlobeIcon } from "@radix-ui/react-icons";

const LocationBar = () => {
  const [zoneContext] = useContext(ZoneContext);
  return (
    <Box
      style={{
        display: "inline-block",
        position: "fixed",
        left: "5px",
        top: "5px",
        opacity: "0.70",
      }}
    >
      {zoneContext.currentZone && (
        <Callout.Root
          size={"1"}
          variant={"outline"}
          color={"red"}
          style={{ backgroundColor: "black", padding: "9px" }}
        >
          <Callout.Icon>
            <GlobeIcon />
          </Callout.Icon>
          <Callout.Text>{zoneContext.currentZone}</Callout.Text>
        </Callout.Root>
      )}
    </Box>
  );
};

export { LocationBar };
