import { Box } from "@radix-ui/themes";
import NewsParagraph from "../components/NewsParagraph.tsx";

function Flash4() {
  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: "10px",
      }}
    >
      <NewsParagraph>
        AI-Generated False Flag Operation Escalates Tensions in the Middle East
      </NewsParagraph>
      <NewsParagraph>August 7, 2024</NewsParagraph>
      <NewsParagraph>
        TEL AVIV, ISRAEL (AP) – Tensions in the Middle East have reached a
        boiling point following an AI-generated false flag operation that
        implicated a neighboring country in a major terrorist attack. The
        sophisticated forgery included fabricated communications, doctored
        images, and manipulated video evidence.
      </NewsParagraph>
      <NewsParagraph>
        Israeli intelligence initially fell for the deception, leading to a
        military response that has since been called off after the truth was
        uncovered. "We were on the brink of war," said an Israeli defense
        official. "This incident highlights the devastating potential of AI to
        create false narratives and provoke real-world conflicts."
      </NewsParagraph>
      <NewsParagraph>
        Diplomatic efforts are now focused on de-escalating the situation, but
        the incident has left a lingering sense of mistrust and fear about the
        capabilities of AI in warfare and espionage.
      </NewsParagraph>
    </Box>
  );
}

export default Flash4;
