import { Box } from "@radix-ui/themes";
import ObfuscatedText from "./ObfuscatedText.tsx";

interface Children {
  children: JSX.Element | string;
}

function NewsParagraph(props: Children) {
  return (
    <Box
      style={{
        fontFamily: "Courier",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <ObfuscatedText>{props.children as string}</ObfuscatedText>
    </Box>
  );
}

export default NewsParagraph;
