import { useContext } from "react";
import { ObfuscationContext } from "../App.tsx";

interface Children {
  children: string;
}

export const obfuscate = (text: string) => {
  let result = "";
  const allChars = Array.from(text);
  const fixedChars = [" "];
  const singleChars = Array.from(text).filter(
    (char) => !fixedChars.includes(char),
  );
  allChars.forEach((char) => {
    if (fixedChars.includes(char)) {
      // If the sting is fixed, just add it
      result += char;
    } else {
      // If string is not fixed, get a random one
      const randomPosition = Math.floor(Math.random() * singleChars.length);
      result += singleChars[randomPosition];
      singleChars.splice(randomPosition, 1); // Remove from the options array, since it should not be selected more than once
    }
  });

  return result;
};

function ObfuscatedText(props: Children) {
  const [obfuscated] = useContext(ObfuscationContext);
  // https://stackoverflow.com/questions/77788840/javascript-how-do-i-shuffle-the-characters-in-a-string-skipping-certain-charac
  if (obfuscated) {
    return <span>{obfuscate(props.children)}</span>;
  } else {
    return <span>{props.children}</span>;
  }
}

export default ObfuscatedText;
