import { Scene } from "phaser";

export class Level0UIScene extends Scene {
  constructor() {
    super("Level0UIScene");
  }

  create(): void {
    this.initListeners();
  }

  private initListeners(): void {}
}
