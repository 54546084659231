import { Box } from "@radix-ui/themes";
import { SpeakerLoudIcon, SpeakerOffIcon } from "@radix-ui/react-icons";
import { useCallback, useContext } from "react";
import { MusicContext } from "../App.tsx";
import { EventBus } from "../game/EventBus.ts";
import { EVENTS_NAME } from "../game/consts.ts";

function Music() {
  const [music, setMusic] = useContext(MusicContext);

  const setGameMusic = useCallback(
    (music: boolean) => {
      setMusic(music);
      EventBus.emit(EVENTS_NAME.music);
    },
    [setMusic],
  );

  return (
    <Box
      style={{
        position: "fixed",
        bottom: "10px",
        left: "10px",
        backgroundColor: "black",
        padding: "10px",
        borderRadius: "30px",
      }}
    >
      {music && <SpeakerLoudIcon onClick={() => setGameMusic(false)} />}
      {!music && <SpeakerOffIcon onClick={() => setGameMusic(true)} />}
    </Box>
  );
}

export default Music;
