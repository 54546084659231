import { Scene } from "phaser";
import { mobileAndTabletCheck } from "../../helpers/gameobject-to-object-point.ts";

export class Preloader extends Scene {
  constructor() {
    super("Preloader");
  }

  init() {
    //  We loaded this image in our Boot Scene, so we can display it here
    this.add.image(512, 384, "background");

    //  A simple progress bar. This is the outline of the bar.
    this.add.rectangle(512, 384, 468, 32).setStrokeStyle(1, 0xffffff);

    //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
    const bar = this.add.rectangle(512 - 230, 384, 4, 28, 0xffffff);

    //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
    this.load.on("progress", (progress: number) => {
      //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
      bar.width = 4 + 460 * progress;
    });
  }

  preload() {
    //  Load the assets for the game - Replace with your own assets
    this.load.setPath("assets");

    this.load.image("logo", "reali7y-overrun-logo.png");
    this.load.image("star", "star.png");
    this.load.image("king", "sprites/king.png");

    this.load.atlas(
      "a-king",
      "spritesheets/a-king.png",
      "spritesheets/a-king_atlas.json",
    );

    this.load.image({
      key: "tiles",
      url: "tilemaps/tiles/dungeon-16-16.png",
    });
    this.load.image({
      key: "tilesInterior",
      url: "scratch-tiled/13_Conference_Hall_Black_Shadow_16x16.png",
    });
    this.load.image({
      key: "tilesModern",
      url: "scratch-tiled/Tilesets_16x16.png",
    });

    this.load.image({
      key: "tiles2LivingRoom",
      url: "scratch-tiled/2_LivingRoom_Black_Shadow_16x16.png",
    });
    this.load.image({
      key: "tiles3Bath",
      url: "scratch-tiled/3_Bathroom_Black_Shadow_16x16.png",
    });
    this.load.image({
      key: "tiles4Bed",
      url: "scratch-tiled/4_Bedroom_Black_Shadow_16x16.png",
    });
    this.load.image({
      key: "tiles5Class",
      url: "scratch-tiled/5_Classroom_and_library_Black_Shadow_16x16.png",
    });
    this.load.image({
      key: "tiles12Kitchen",
      url: "scratch-tiled/12_Kitchen_Black_Shadow_16x16.png",
    });
    this.load.image({
      key: "tiles14Basement",
      url: "scratch-tiled/14_Basement_Black_Shadow_16x16.png",
    });
    this.load.image({
      key: "tiles18Jail",
      url: "scratch-tiled/18_Jail_Black_Shadow_16x16.png",
    });
    this.load.image({
      key: "tiles19Hospital",
      url: "scratch-tiled/19_Hospital_Black_Shadow_16x16.png",
    });
    this.load.image({
      key: "tilesExteriors",
      url: "scratch-tiled/Modern_Exteriors_Complete_Tileset.png",
    });
    this.load.image({
      key: "tilesNpc",
      url: "scratch-tiled/npc_tileset.png",
    });

    this.load.spritesheet("tiles_spr", "tilemaps/tiles/dungeon-16-16.png", {
      frameWidth: 16,
      frameHeight: 16,
    });

    this.load.tilemapTiledJSON("game24-a", "tilemaps/json/game24-a.tmj");
    this.load.tilemapTiledJSON("layer0", "tilemaps/json/layer0.tmj");

    if (!mobileAndTabletCheck()) {
      this.load.audio("0", ["scratch-tiled/some random thing.mp3"]);
      this.load.audio("1", [
        "scratch-tiled/Gentle_rain_at_twilight_10-28-2023.mp3",
      ]);
      this.load.audio("2", ["scratch-tiled/Composition_1.mp3"]);
      this.load.audio("3", ["scratch-tiled/Composition_2.mp3"]);
      this.load.audio("4", ["scratch-tiled/Composition_3.mp3"]);
      this.load.audio("5", ["scratch-tiled/w3ydf.mp3"]);
      this.load.audio("6", [
        "scratch-tiled/Moon_over_an_abandoned_factory_7-26-2022 (1).mp3",
      ]);
      this.load.audio("7", [
        "scratch-tiled/Offbeat_encounter_with_a_happy_bat_8-11-2022.mp3",
      ]);
      this.load.audio("8", [
        "scratch-tiled/blackjack parlor music WIP 4-25-2024.mp3",
      ]);
      this.load.audio("9", ["scratch-tiled/elgion.mp3"]);
      this.load.audio("10", [
        "scratch-tiled/Gentle_rain_at_twilight_10-28-2023.mp3",
      ]);
      this.load.audio("11", [
        "scratch-tiled/jesse hollow knight big title clam (calm) holow piano trio.mp3",
      ]);
      this.load.audio("12", ["scratch-tiled/ahk.mp3"]);
    }
  }

  create() {
    //  When all the assets have loaded, it's often worth creating global objects here that the rest of the game can use.
    //  For example, you can define global animations here, so we can use them in other scenes.
    //  Move to the MainMenu. You could also swap this for a Scene Transition, such as a camera fade.
    this.scene.start("MainMenu");
  }
}
