import { Boot } from "./game/scenes/structure/Boot.ts";
import { Preloader } from "./game/scenes/structure/Preloader.ts";
import { MainMenu } from "./game/scenes/structure/MainMenu.ts";
import { Game } from "./game/scenes/structure/Game.ts";
import { GameOver } from "./game/scenes/structure/GameOver.ts";
import { Level1 } from "./game/scenes/levels/Level1.ts";
import { Level1UIScene } from "./game/scenes/ui/Level1UIScene.ts";
import { Level0UIScene } from "./game/scenes/ui/Level0UIScene.ts";
import { SoundTrackScene } from "./game/scenes/ui/SoundTrackScene.ts";
import { Level0 } from "./game/scenes/levels/Level0.ts";

export const config: Phaser.Types.Core.GameConfig = {
  title: "REALI7Y OVERRUN",
  type: Phaser.WEBGL,
  parent: "game-container",
  backgroundColor: "#dedded",
  canvasStyle: `display: block; width: 100%; height: 100%;`,
  autoFocus: true,
  audio: {
    disableWebAudio: false,
  },
  scale: {
    mode: Phaser.Scale.NONE,
    // autoCenter: Phaser.Scale.CENTER_BOTH,
    width: window.innerWidth,
    height: window.innerHeight,
  },
  physics: {
    default: "arcade",
    arcade: {
      debug: false,
    },
  },
  render: {
    antialiasGL: false,
    pixelArt: true,
  },
  callbacks: {
    preBoot: () => {
      //
    },
    postBoot: () => {
      //
    },
  },
  scene: [
    Boot,
    Preloader,
    MainMenu,
    Game,
    Level0,
    Level1,
    GameOver,
    SoundTrackScene,
    Level0UIScene,
    Level1UIScene,
  ],
};
