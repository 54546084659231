import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import { Game } from "./views/Game";
import NewsArticle from "./views/NewsArticle.tsx";
import Nsa from "./articles/Nsa";
import Mit from "./articles/Mit";
import Fingerpointing from "./articles/Fingerpointing";
import World from "./articles/World";
import Ap from "./articles/Ap";
import Flash1 from "./articles/1";
import Flash2 from "./articles/2";
import Flash3 from "./articles/3";
import Flash4 from "./articles/4";
import Flash5 from "./articles/5";
import Flash6 from "./articles/6";
import Long1 from "./articles/l1";
import Long2 from "./articles/l2";
import Long3 from "./articles/l3";
import Long4 from "./articles/l4";
import Context from "./views/Context.tsx";
import Odds from "./views/Odds.tsx";
import Introduction from "./views/Introduction.tsx";
import { createContext, useCallback, useEffect, useState } from "react";
import Account from "./views/Account.tsx";
import {
  sendMessage,
  AccountStruct,
  PlayerStruct,
  getFactionInventory,
  FactionStruct,
  getPlayer,
  loginWithHash,
  getPlayerScoreboard,
} from "./api.ts";
import Issues from "./views/Issues.tsx";
import Leaderboard from "./views/Leaderboard.tsx";

export const ObfuscationContext = createContext<
  [boolean, (_: boolean) => void]
>([true, (_) => {}]);

export const MusicContext = createContext<[boolean, (_: boolean) => void]>([
  true,
  (_) => {},
]);

export const AccountContext = createContext<
  [AccountStruct | null, (_: AccountStruct | null) => void]
>([null, (_) => {}]);

export const PlayerContext = createContext<
  [PlayerStruct | null, (_: PlayerStruct | null) => void]
>([null, (_) => {}]);

export const FactionContext = createContext<
  [FactionStruct | null, (_: FactionStruct | null) => void]
>([null, (_) => {}]);

export const WebSocketContext = createContext<WebSocket | null>(null);

export interface ChallengeContextInterface {
  challengeId?: number;
  challengeTitle?: string;
  challengeText?: string;
  challengeDialogOpen?: boolean;
  challengeUnderway?: boolean;
}

export const ChallengeContext = createContext<
  [ChallengeContextInterface, (_: ChallengeContextInterface) => void]
>([{ challengeDialogOpen: true }, (_) => {}]);

function App() {
  const [obfuscate, setObfuscate] = useState(
    localStorage.getItem("obfuscate") !== "false",
  );
  const [music, setMusic] = useState(true);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [account, setAccount] = useState<AccountStruct | null>(
    localStorage.getItem("account")
      ? JSON.parse(localStorage.getItem("account")!)
      : null,
  );
  const [player, setPlayer] = useState<PlayerStruct | null>(
    localStorage.getItem("player")
      ? JSON.parse(localStorage.getItem("player")!)
      : null,
  );
  const [faction, setFaction] = useState<FactionStruct | null>(null);
  const [challengeContext, setChallengeContext] =
    useState<ChallengeContextInterface>({});

  const localStorageWrappedSetObfuscate = useCallback((obf: boolean) => {
    setObfuscate(obf);
    localStorage.setItem("obfuscate", obf.toString());
  }, []);

  const localStorageWrappedSetAccount = useCallback(
    (account: AccountStruct | null) => {
      setAccount(account);
      localStorage.setItem("account", JSON.stringify(account));
    },
    [],
  );

  const localStorageWrappedSetPlayer = useCallback(
    (player: PlayerStruct | null) => {
      setPlayer(player);
      localStorage.setItem("player", JSON.stringify(player));
    },
    [],
  );

  const setWs = useCallback(() => {
    if (websocket === null) {
      // const wsBase = "ws://" + document.location.host + "/cmd";
      const wsBase = window.location.href.includes("localhost")
        ? "ws://localhost:3030/cmd"
        : "wss://reali7y-over.run/cmd";
      console.log("Creating ws from " + wsBase);
      //let ws = new WebSocket(wsBase + "/v1/player/" +
      //    this.account_id + "?auth_token=" + escape(window.DARKNET_HEADER));
      const ws = new WebSocket(wsBase);

      ws.onopen = function (_event) {
        console.log("on open");
      };

      ws.onclose = (_event) => {
        console.log("======>WEBSOCKET CLOSED");
        // log out
        localStorageWrappedSetAccount(null);
        localStorageWrappedSetPlayer(null);
        setTimeout(() => {
          setWebsocket(setWs());
        }, 5000);
      };

      ws.onmessage = function (event) {
        const msg = JSON.parse(event.data);
        if (msg.Error) {
          console.log("Hit error condition; logging error!");
          console.log(msg);
          if (msg.Error.id === "NoAccountFound") {
            console.log("Login failed");
            alert("Login failed");
          } else if (msg.Error.id === "NoPlayerFound") {
            if (account === null) {
              console.log("no account found, need to create player");
              localStorageWrappedSetAccount({
                username: "placeholder",
                password: "placeholder",
              });
            }
          } else if (msg.Error.id === "FailedToCreateAccount") {
            alert(
              "Account creation failed; might you have an account already?",
            );
          } else if (msg.Error.id === "NoPlayerInWC") {
            if (player && ws) {
              console.log("login with hash");
              loginWithHash(ws, player.login_hash);
            } else if (!player) {
              console.log("no player");
            } else if (!ws) {
              console.log("no websocket");
            }
            // else {
            //   console.log("Player was disconnected; reset local state");
            //   alert(
            //     "Sorry, we have to have you re-login. So so sorry. Sorry. Terribly sorry.",
            //   );
            //   localStorageWrappedSetAccount(null);
            //   localStorageWrappedSetPlayer(null);
            //   window.location.href = "/account";
            // }
          } else if (msg.Error.id === "WrongChallengeAnswer") {
            alert("That answer was incorrect. Bummer.");
            setChallengeContext((prevState) => {
              return { ...prevState, challengeUnderway: false };
            });
          } else if (msg.Error.id === "ChallengeAlreadyComplete") {
            alert("You already solved that challenge, silly.");
          }
        } else {
          console.log(msg);
          if (msg.LoadPlayerResult) {
            console.log(`setting player`);
            setChallengeContext((prevState) => {
              return { ...prevState, challengeUnderway: false };
            });
            localStorageWrappedSetPlayer(msg.LoadPlayerResult.plr);
            localStorageWrappedSetAccount({
              username: msg.LoadPlayerResult.plr.username,
              password: "youwish",
            });
          }
          if (msg.FactionInv) {
            console.log("setting faction inventory");
            setFaction(msg.FactionInv.faction);
            const existingFactionDict = localStorage.getItem("factionList");
            let parsedFactions: Record<string, FactionStruct> = {};
            if (existingFactionDict) {
              parsedFactions = JSON.parse(existingFactionDict);
            }
            if (
              parsedFactions[msg.FactionInv.faction.faction_id] &&
              parsedFactions[msg.FactionInv.faction.faction_id].inv
                .game_item_bag.length ===
                msg.FactionInv.faction.inv.game_item_bag.length
            ) {
              console.log(
                "Already have this faction listed and its score is the same",
              );
            } else {
              console.log(
                `Adding or replacing faction ${msg.FactionInv.faction.faction_id}`,
              );
              parsedFactions[msg.FactionInv.faction.faction_id] =
                msg.FactionInv.faction;
              const newFactions = JSON.stringify(parsedFactions);
              localStorage.setItem("factionList", newFactions);
            }
          }
          if (msg.ChallengeText) {
            const chalCon = {
              challengeId: msg.ChallengeText.challenge_id,
              challengeTitle: msg.ChallengeText.title,
              challengeText: msg.ChallengeText.description,
              challengeDialogOpen: true,
              challengeUnderway: false,
            };
            setChallengeContext(chalCon);
            console.log(chalCon);
          }
          if (msg.PlayerScoreBoard) {
            console.log("Got player scoreboard");
            localStorage.setItem(
              "playerScoreboard",
              JSON.stringify(msg.PlayerScoreBoard.player_score_board),
            );
          }
        }
      };

      return ws;
    } else {
      return websocket;
    }
  }, [
    account,
    localStorageWrappedSetAccount,
    localStorageWrappedSetPlayer,
    player,
    websocket,
  ]);

  useEffect(() => {
    const playerScoreboardTimer = setInterval(() => {
      if (websocket) {
        getPlayerScoreboard(websocket);
      }
    }, 1000 * 10);
    return () => {
      clearInterval(playerScoreboardTimer);
    };
  }, [websocket]);

  useEffect(() => {
    setTimeout(() => {
      console.log("Testing player login status");
      if (player?.player_id && websocket) {
        getPlayer(websocket, player?.player_id);
      }
    }, 1000);
  }, [player?.player_id, websocket]);

  useEffect(() => {
    let factionInterval: string | number | NodeJS.Timeout | undefined;
    if (websocket && player) {
      console.log("Setting faction fetch");
      factionInterval = setInterval(() => {
        getFactionInventory(websocket, player.faction_id);
      }, 10000);
    }
    return () => {
      console.log("Shutting down faction fetch");
      if (factionInterval) {
        clearInterval(factionInterval);
      }
    };
  }, [player, websocket]);

  useEffect(() => {
    console.log("Enter game");
    if (websocket === null) {
      console.log("====>SETTING WEBSOCKET");
      setWebsocket(setWs());
    }
    console.log("Start playing echo pong");
    const pongInterval = setInterval(() => {
      if (websocket) {
        sendMessage(websocket, { Ping: { timestamp: new Date().getTime() } });
      }
    }, 1000 * 30);

    return () => {
      console.log("Leaving game and clearing pong game");
      clearInterval(pongInterval);
    };
  }, [setWs, websocket]);

  return (
    <ObfuscationContext.Provider
      value={[obfuscate, localStorageWrappedSetObfuscate]}
    >
      <MusicContext.Provider value={[music, setMusic]}>
        <AccountContext.Provider
          value={[account, localStorageWrappedSetAccount]}
        >
          <PlayerContext.Provider
            value={[player, localStorageWrappedSetPlayer]}
          >
            <FactionContext.Provider value={[faction, setFaction]}>
              <WebSocketContext.Provider value={websocket}>
                <ChallengeContext.Provider
                  value={[challengeContext, setChallengeContext]}
                >
                  <BrowserRouter>
                    <Routes>
                      <Route path="/" element={<Home />} />

                      <Route path="/context" element={<Context />} />
                      <Route
                        path="/nsa"
                        element={<NewsArticle Article={Nsa} />}
                      />
                      <Route
                        path="/ap"
                        element={<NewsArticle Article={Ap} />}
                      />
                      <Route
                        path="/world"
                        element={<NewsArticle Article={World} />}
                      />
                      <Route
                        path="/mit"
                        element={<NewsArticle Article={Mit} />}
                      />
                      <Route
                        path="/fingerpointing"
                        element={<NewsArticle Article={Fingerpointing} />}
                      />
                      <Route
                        path="/flash1"
                        element={<NewsArticle Article={Flash1} />}
                      />
                      <Route
                        path="/flash2"
                        element={<NewsArticle Article={Flash2} />}
                      />
                      <Route
                        path="/flash3"
                        element={<NewsArticle Article={Flash3} />}
                      />
                      <Route
                        path="/flash4"
                        element={<NewsArticle Article={Flash4} />}
                      />
                      <Route
                        path="/flash5"
                        element={<NewsArticle Article={Flash5} />}
                      />
                      <Route
                        path="/flash6"
                        element={<NewsArticle Article={Flash6} />}
                      />
                      <Route
                        path="/long1"
                        element={<NewsArticle Article={Long1} />}
                      />
                      <Route
                        path="/long2"
                        element={<NewsArticle Article={Long2} />}
                      />
                      <Route
                        path="/long3"
                        element={<NewsArticle Article={Long3} />}
                      />
                      <Route
                        path="/long4"
                        element={<NewsArticle Article={Long4} />}
                      />
                      <Route path="/introduction" element={<Introduction />} />
                      <Route path="/odds" element={<Odds />} />

                      <Route path="/account" element={<Account />} />
                      <Route path="/game" element={<Game />} />
                      <Route path="/leaderboard" element={<Leaderboard />} />
                      <Route path="/issues" element={<Issues />} />
                    </Routes>
                  </BrowserRouter>
                </ChallengeContext.Provider>
              </WebSocketContext.Provider>
            </FactionContext.Provider>
          </PlayerContext.Provider>
        </AccountContext.Provider>
      </MusicContext.Provider>
    </ObfuscationContext.Provider>
  );
}

export default App;
