import { createContext, useRef, useState } from "react";
import { PhaserGame } from "./PhaserGame";
import { config as gameConfig } from "../game-config";
import { Navbar } from "../components/Navbar.tsx";
import { LocationBar } from "../components/LocationBar.tsx";
import StoryDialog from "../components/StoryDialog.tsx";
import { Box } from "@radix-ui/themes";
import { Countdown } from "../components/Countdown.tsx";
import { Faction } from "../components/Faction.tsx";
import ChallengeDialog from "../components/ChallengeDialog.tsx";
import Music from "../components/Music.tsx";
// import ChatDialog from "../components/ChatDialog.tsx";

export interface IRefPhaserGame {
  game: Phaser.Game | null;
  scene: Phaser.Scene | null;
}

interface ZoneContextInterface {
  currentZone?: string;
}

interface StoryContextInterface {
  storyTitle?: string;
  storyText?: string;
  storyDialogOpen?: boolean;
}

export const ZoneContext = createContext<
  [ZoneContextInterface, (_: ZoneContextInterface) => void]
>([{}, (_) => {}]);

export const StoryContext = createContext<
  [StoryContextInterface, (_: StoryContextInterface) => void]
>([{ storyDialogOpen: true }, (_) => {}]);

function Game() {
  return (
    <Box>
      <Navbar />
      <Countdown />
      <Faction />
      {/* <ChatDialog /> */}
      <InnerGame />
    </Box>
  );
}

function InnerGame() {
  //  References to the PhaserGame component (game and scene are exposed)
  const phaserRef = useRef<IRefPhaserGame | null>(null);
  const [storyContext, setStoryContext] = useState({});
  const [zoneContext, setZoneContext] = useState({});

  // Event emitted from the PhaserGame component
  const currentScene = (_scene: Phaser.Scene) => {
    // console.log(scene);
  };

  return (
    <div id="game-app">
      <ZoneContext.Provider value={[zoneContext, setZoneContext]}>
        <StoryContext.Provider value={[storyContext, setStoryContext]}>
          <LocationBar />
          <StoryDialog />
          <ChallengeDialog />
          <Music />
          <PhaserGame
            ref={phaserRef}
            currentActiveScene={currentScene}
            config={gameConfig}
          />
        </StoryContext.Provider>
      </ZoneContext.Provider>
    </div>
  );
}

export { Game };
