import { Box, Flex, Section } from "@radix-ui/themes";
import { Navbar } from "../components/Navbar.tsx";
import React from "react";
import { Countdown } from "../components/Countdown.tsx";
import { Faction } from "../components/Faction.tsx";
// import ChatDialog from "../components/ChatDialog.tsx";

interface DynamicComponentProps {
  Article: React.ComponentType<any>;
}

const NewsArticle: React.FC<DynamicComponentProps> = ({ Article }) => {
  return (
    <Box>
      <Navbar />
      <Countdown />
      <Faction />
      {/* <ChatDialog /> */}
      <Flex justify={"center"}>
        <Box width={"1000px"}>
          <Section>
            <Article />
          </Section>
        </Box>
      </Flex>
    </Box>
  );
};

export default NewsArticle;
