import {
  forwardRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import StartGame from "../game/main";
import { EventBus } from "../game/EventBus";
import { ObfuscationContext, WebSocketContext } from "../App.tsx";
import { EVENTS_NAME } from "../game/consts.ts";
import { IRefPhaserGame, StoryContext, ZoneContext } from "./Game.tsx";
import { getChallengeText } from "../api.ts";

interface IProps {
  currentActiveScene?: (scene_instance: Phaser.Scene) => void;
  config: Phaser.Types.Core.GameConfig;
}
export const PhaserGame = forwardRef<IRefPhaserGame, IProps>(
  function PhaserGame({ config, currentActiveScene }, ref) {
    const game = useRef<Phaser.Game | null>(null!);
    const [, setObfuscate] = useContext(ObfuscationContext);
    const [storyContext, setStoryContext] = useContext(StoryContext);
    const [, setZoneContext] = useContext(ZoneContext);
    const websocket = useContext(WebSocketContext);

    useLayoutEffect(() => {
      if (game.current === null) {
        game.current = StartGame(config, "game-container");

        if (typeof ref === "function") {
          ref({ game: game.current, scene: null });
        } else if (ref) {
          ref.current = { game: game.current, scene: null };
        }
      }

      return () => {
        if (game.current) {
          game.current.destroy(true);
          game.current = null;
        }
      };
    }, [config, ref]);

    useEffect(() => {
      EventBus.on(EVENTS_NAME.zoneEnter, (name: string) => {
        setZoneContext({ currentZone: name });
      });
      return () => {
        EventBus.removeListener(EVENTS_NAME.zoneEnter);
      };
    });

    useEffect(() => {
      EventBus.on(EVENTS_NAME.clearGameUi, () => {
        setZoneContext({ currentZone: "" });
        setStoryContext({
          ...storyContext,
          storyDialogOpen: false,
          storyTitle: "",
          storyText: "",
        });
      });
      return () => {
        EventBus.removeListener(EVENTS_NAME.clearGameUi);
      };
    });

    useEffect(() => {
      EventBus.on(EVENTS_NAME.showStoryline, (name: string) => {
        const [localTitle, localText] = name.split(":", 2);
        setStoryContext({
          storyTitle: localTitle,
          storyText: localText,
          storyDialogOpen: true,
        });
      });
      return () => {
        EventBus.removeListener(EVENTS_NAME.showStoryline);
      };
    });

    useEffect(() => {
      EventBus.on(EVENTS_NAME.showChallenge, (name: string) => {
        if (websocket) {
          getChallengeText(websocket, parseInt(name));
        }
      });
      return () => {
        EventBus.removeListener(EVENTS_NAME.showChallenge);
      };
    });

    useEffect(() => {
      EventBus.on(
        EVENTS_NAME.currentSceneReady,
        (scene_instance: Phaser.Scene) => {
          if (currentActiveScene && typeof currentActiveScene === "function") {
            currentActiveScene(scene_instance);
          }

          if (typeof ref === "function") {
            ref({ game: game.current, scene: scene_instance });
          } else if (ref) {
            ref.current = { game: game.current, scene: scene_instance };
          }
        },
      );
      return () => {
        EventBus.removeListener(EVENTS_NAME.currentSceneReady);
      };
    }, [currentActiveScene, ref]);

    useEffect(() => {
      EventBus.on(EVENTS_NAME.unobfuscateText, () => {
        setObfuscate(false);
      });
      return () => {
        EventBus.removeListener(EVENTS_NAME.unobfuscateText);
      };
    }, [setObfuscate]);

    return <div id="game-container"></div>;
  },
);
