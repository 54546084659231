import { Box } from "@radix-ui/themes";
import NewsParagraph from "../components/NewsParagraph.tsx";

function Nsa() {
  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: "10px",
      }}
    >
      <NewsParagraph>
        Confusion and Chaos: Reports of Assassination Attempt on Serbian Prime
        Minister Viloš Mučević Emerge
      </NewsParagraph>
      <NewsParagraph>July 28, 2024</NewsParagraph>
      <NewsParagraph>
        BELGRADE, SERBIA (AP) – In a harrowing and rapidly evolving situation,
        reports have surfaced regarding an assassination attempt on Serbian
        Prime Minister Viloš Mučević. The incident has plunged the nation into a
        state of confusion and heightened tension, as conflicting accounts from
        inside the ministry and disturbing video clips add to the uncertainty.
      </NewsParagraph>
      <NewsParagraph>
        According to initial reports, an apparent assassination attempt took
        place earlier today during a public appearance by Prime Minister
        Mučević. Eyewitnesses described a chaotic scene, with sounds of gunfire
        and frantic security personnel rushing the Prime Minister to safety.
        However, shortly after these reports emerged, the Serbian Ministry of
        Internal Affairs issued a statement suggesting that no such event had
        occurred.
      </NewsParagraph>
      <NewsParagraph>
        "The level of sophistication in these AI-generated fakes is
        unprecedented," said an NSA spokesperson. "We are particularly concerned
        about the Serbian incident, which has far-reaching implications for
        international relations and domestic perceptions."
      </NewsParagraph>
      <NewsParagraph>
        "We can confirm that Prime Minister Viloš Mučević is safe and unharmed,"
        said a ministry spokesperson. "There was no assassination attempt, and
        the situation is under control. Any reports to the contrary are false
        and should be disregarded."
      </NewsParagraph>
      <NewsParagraph>
        Despite the official denial, several news outlets and social media
        platforms have circulated what appear to be live video clips showing the
        alleged assassination attempt. The footage, which has been viewed and
        shared millions of times, depicts a chaotic scene with what appears to
        be Prime Minister Mučević being hurriedly escorted by security forces
        amid audible gunfire.
      </NewsParagraph>
      <NewsParagraph>
        "These findings underscore the urgent need for increased scrutiny of
        news articles and media content," Falken stated. "The ability of
        generative AI to create highly realistic, yet entirely false,
        information poses a significant threat to our understanding of truth and
        reality. We must accelerate the development of technologies capable of
        detecting AI-generated content to safeguard the integrity of
        information."
      </NewsParagraph>
      <NewsParagraph>
        The conflicting information has left the public and international
        observers in a state of confusion and alarm. The inability to verify the
        authenticity of the video clips or obtain consistent accounts from
        official sources has only heightened the sense of uncertainty.
      </NewsParagraph>
      <NewsParagraph>
        "This is an extremely volatile and confusing situation," said a
        journalist reporting from Belgrade. "On one hand, we have official
        statements denying any attack, while on the other, we have visual
        evidence that suggests otherwise. It's difficult to ascertain what is
        actually happening inside Serbia right now."
      </NewsParagraph>
      <NewsParagraph>
        The apparent disconnect between official statements and the widely
        circulated video footage has sparked widespread speculation and concern.
        Some analysts suggest that the conflicting reports could be the result
        of misinformation or deliberate attempts to manipulate public
        perception.
      </NewsParagraph>
      <NewsParagraph>
        International leaders have expressed their concern and called for
        clarity. "We are closely monitoring the situation in Serbia," said a
        spokesperson for the European Union. "We urge the Serbian authorities to
        provide transparent and accurate information to address the growing
        confusion and reassure the public."
      </NewsParagraph>
      <NewsParagraph>
        As the situation continues to unfold, the urgency and strident tone of
        the conflicting reports underscore the terrifying inability to get solid
        answers to fundamental questions. Is Prime Minister Mučević truly safe,
        or is the nation witnessing a sophisticated disinformation campaign? The
        lack of clarity only deepens the fear and uncertainty among the Serbian
        populace and the international community.
      </NewsParagraph>
      <NewsParagraph>
        The AP will continue to follow this story closely, providing updates as
        more information becomes available. In the meantime, we urge readers to
        remain cautious and critical of the information they encounter, as the
        truth behind this alarming incident remains elusive.
      </NewsParagraph>
    </Box>
  );
}

export default Nsa;
