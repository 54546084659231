import { EventBus } from "../../EventBus.ts";
import { Scene } from "phaser";
import { EVENTS_NAME } from "../../consts.ts";

export class Game extends Scene {
  camera: Phaser.Cameras.Scene2D.Camera;
  background: Phaser.GameObjects.Image;
  gameText: Phaser.GameObjects.Text;
  player1!: Phaser.GameObjects.Sprite;

  constructor() {
    super("Game");
  }

  create() {
    this.camera = this.cameras.main;
    this.camera.setBackgroundColor(0x00ff00);

    this.background = this.add.image(512, 384, "background");
    this.background.setAlpha(0.5);

    this.player1 = this.add.sprite(700, 700, "king");

    this.gameText = this.add
      .text(
        512,
        384,
        "Make something fun!\nand share it with us:\nsupport@phaser.io",
        {
          fontFamily: "Arial Black",
          fontSize: 38,
          color: "#ffffff",
          stroke: "#000000",
          strokeThickness: 8,
          align: "center",
        },
      )
      .setOrigin(0.5)
      .setDepth(100);

    EventBus.emit(EVENTS_NAME.currentSceneReady, this);
  }

  changeScene() {
    this.scene.start("Level0UIScene");
    this.scene.start("Level0");
  }
}
