import { Scene } from "phaser";
import { EventBus } from "../../EventBus.ts";
import { EVENTS_NAME } from "../../consts.ts";

export class MainMenu extends Scene {
  constructor() {
    super("MainMenu");
  }

  update() {}

  create() {
    const background = this.add.image(
      window.innerWidth / 2,
      window.innerHeight / 2,
      "background",
    );
    background.setDisplaySize(window.innerWidth, window.innerHeight);
    this.add
      .image(window.innerWidth / 2, window.innerHeight / 2, "logo")
      .setDepth(100);

    this.add
      .text(window.innerWidth / 2, 90, "Main Menu", {
        fontFamily: "Courier",
        fontSize: 38,
        color: "#ffffff",
        stroke: "#000000",
        strokeThickness: 8,
        align: "center",
      })
      .setOrigin(0.5)
      .setDepth(100);

    this.add
      .text(window.innerWidth / 2, 890, "Click to continue", {
        fontFamily: "Courier",
        fontSize: 38,
        color: "#ffffff",
        stroke: "#000000",
        strokeThickness: 8,
        align: "center",
      })
      .setOrigin(0.5)
      .setDepth(100);

    this.add
      .text(
        100,
        800,
        "Game backend by cmdc0dez\nMaps by the WildSamosa\nGame frontend by 0a\nOriginal music by kierkecoder\nAdditional coding by babu\nSprites/Tilesets by Limezu",
        {
          fontFamily: "Courier",
          fontSize: 10,
          color: "#ffffff",
          stroke: "#000000",
          strokeThickness: 8,
          align: "left",
        },
      )
      .setOrigin(0.5)
      .setDepth(100);

    this.input.once("pointerdown", () => {
      this.scene.start("SoundTrackScene");
      const playerLocationLevel0 = localStorage.getItem("playerLocationLevel0");
      let x0 = 0;
      let x1 = 0;
      if (playerLocationLevel0) {
        x0 = JSON.parse(playerLocationLevel0)[0];
        x1 = JSON.parse(playerLocationLevel0)[1];
      }
      const playerLocationLevel1 = localStorage.getItem("playerLocationLevel1");
      if (playerLocationLevel1 || (x0 > 735 && x1 > 440)) {
        this.scene.start("Level1UIScene");
        this.scene.start("Level1");
      } else {
        this.scene.start("Level0UIScene");
        this.scene.start("Level0");
      }
    });
    EventBus.emit(EVENTS_NAME.currentSceneReady, this);
  }
}
