import { Tilemaps } from "phaser";
import { EventBus } from "../../EventBus.ts";
import { Player } from "../../classes/player.ts";
import {
  gameObjectsToObjectPoints,
  mobileAndTabletCheck,
} from "../../helpers/gameobject-to-object-point.ts";
import { EVENTS_NAME } from "../../consts.ts";
import { Enemy, Enemy2, Enemy3, EnemyActor } from "../../classes/enemy.ts";
import { BaseScene } from "./Level0.ts";
import { Mrpas } from "mrpas";

const DEBUG = false;
export const DEFAULT_PLAYER_START_LOCATION = [2335, 3110];

export class Level1 extends BaseScene {
  tileset!: Tilemaps.Tileset;
  tilesetInterior!: Tilemaps.Tileset;
  tilesetModern!: Tilemaps.Tileset;
  tilesets2Living!: Tilemaps.Tileset;
  tilesets3Bath!: Tilemaps.Tileset;
  tilesets4Bed!: Tilemaps.Tileset;
  tilesets5Class!: Tilemaps.Tileset;
  tilesets12Kitchen!: Tilemaps.Tileset;
  tilesets14Basement!: Tilemaps.Tileset;
  tilesets18Jail!: Tilemaps.Tileset;
  tilesets19Hospital!: Tilemaps.Tileset;
  tilesetsPrac!: Tilemaps.Tileset;
  tilesetsNpc!: Tilemaps.Tileset;
  chests!: Phaser.GameObjects.Sprite[];
  enemies!: Phaser.GameObjects.Sprite[];

  constructor() {
    super("Level1");
    this.mapName = "Convention center and Strip";
  }

  create(): void {
    this.initMap();
    const playerStartLocation = localStorage.getItem("playerLocationLevel1")
      ? JSON.parse(localStorage.getItem("playerLocationLevel1")!)
      : DEFAULT_PLAYER_START_LOCATION;
    this.player1 = new Player(
      this,
      playerStartLocation[0],
      playerStartLocation[1],
    );
    this.fov = new Mrpas(this.map.width, this.map.height, (x, y) => {
      const tile = this.wallsLayer!.getTileAt(x, y);
      return !tile || !tile.collides;
    });
    this.physics.add.collider(this.player1, this.wallsLayer);
    this.initCamera();
    this.initZones();
    this.initStorylines();
    this.initChallenges();
    this.initTeleporters();

    if (mobileAndTabletCheck()) {
      console.log("skipping mobile initialization");
    } else {
      this.initChests();
      this.initEnemies();
    }
    EventBus.emit(EVENTS_NAME.currentSceneReady, this);
    EventBus.emit(EVENTS_NAME.unobfuscateText);
  }

  update(): void {
    this.globalUpdate();
    localStorage.setItem(
      "playerLocationLevel1",
      JSON.stringify([this.player1.x, this.player1.y]),
    );
  }

  changeScene() {
    this.scene.stop("Level1UIScene");
    this.scene.stop("SoundTrackScene");
    this.scene.start("GameOver");
  }

  initTeleporters(): void {
    const teleporters = gameObjectsToObjectPoints(
      this.map.filterObjects("Zones", (obj) => obj.name === "Teleporter"),
    );
    const teleporterList = teleporters.map((teleporter) => {
      return this.physics.add
        .sprite(
          teleporter.x + teleporter.width / 2,
          teleporter.y + teleporter.height / 2,
          "tiles_spr",
          595,
        )
        .setName(teleporter.properties[0].value)
        .setSize(teleporter.width, teleporter.height)
        .setAlpha(0);
    });
    teleporterList.forEach((teleporter) => {
      this.physics.add.overlap(this.player1, teleporter, (_obj1, _obj2) => {
        const [x, y] = teleporter.name.split(",").map(Number);
        console.log(`teleporting to ${x}, ${y}}`);
        this.player1.x = x;
        this.player1.y = y;
      });
    });
  }

  initMap(): void {
    this.map = this.make.tilemap({
      key: "game24-a",
      tileWidth: 16,
      tileHeight: 16,
    });
    const ltileset = this.map.addTilesetImage("game24-a", "tiles");
    if (ltileset) {
      this.tileset = ltileset;
    }
    const ltilesetModern = this.map.addTilesetImage(
      "Interior Modern",
      "tilesModern",
    );
    if (ltilesetModern) {
      this.tilesetModern = ltilesetModern;
    }
    const ltilesetInterior = this.map.addTilesetImage(
      "Interior Conference Hall",
      "tilesInterior",
    );
    if (ltilesetInterior) {
      this.tilesetInterior = ltilesetInterior;
    }
    const ltilesets2Living = this.map.addTilesetImage(
      "Living Room",
      "tiles2LivingRoom",
    );
    if (ltilesets2Living) {
      this.tilesets2Living = ltilesets2Living;
    }
    const ltilesets3Bath = this.map.addTilesetImage("Bathroom", "tiles3Bath");
    if (ltilesets3Bath) {
      this.tilesets3Bath = ltilesets3Bath;
    }
    const ltilesets4Bed = this.map.addTilesetImage("Bedroom", "tiles4Bed");
    if (ltilesets4Bed) {
      this.tilesets4Bed = ltilesets4Bed;
    }
    const ltilesets5Class = this.map.addTilesetImage(
      "Classroom",
      "tiles5Class",
    );
    if (ltilesets5Class) {
      this.tilesets5Class = ltilesets5Class;
    }
    const ltilesets12Kitchen = this.map.addTilesetImage(
      "Kitchen",
      "tiles12Kitchen",
    );
    if (ltilesets12Kitchen) {
      this.tilesets12Kitchen = ltilesets12Kitchen;
    }
    const ltilesets14Basement = this.map.addTilesetImage(
      "Basement",
      "tiles14Basement",
    );
    if (ltilesets14Basement) {
      this.tilesets14Basement = ltilesets14Basement;
    }
    const ltilesets18Jail = this.map.addTilesetImage("Jail", "tiles18Jail");
    if (ltilesets18Jail) {
      this.tilesets18Jail = ltilesets18Jail;
    }
    const ltilesets19Hospital = this.map.addTilesetImage(
      "Hospital",
      "tiles19Hospital",
    );
    if (ltilesets19Hospital) {
      this.tilesets19Hospital = ltilesets19Hospital;
    }
    const ltilesetsPrac = this.map.addTilesetImage(
      "Practicce",
      "tilesExteriors",
    );
    if (ltilesetsPrac) {
      this.tilesetsPrac = ltilesetsPrac;
    }
    const ltilesetsNpc = this.map.addTilesetImage("npc", "tilesNpc");
    if (ltilesetsNpc) {
      this.tilesetsNpc = ltilesetsNpc;
    }

    const tilesets = [
      this.tileset,
      this.tilesetInterior,
      this.tilesetModern,
      this.tilesets2Living,
      this.tilesets3Bath,
      this.tilesets4Bed,
      this.tilesets5Class,
      this.tilesets12Kitchen,
      this.tilesets14Basement,
      this.tilesets18Jail,
      this.tilesets19Hospital,
      this.tilesetsPrac,
      this.tilesetsNpc,
    ];
    const lgroundLayer = this.map.createLayer("Ground", tilesets, 0, 0);
    if (lgroundLayer) {
      this.groundLayer = lgroundLayer;
    }
    const lfurnishingLayer = this.map.createLayer(
      "Furnishing Tiles",
      tilesets,
      0,
      0,
    );
    if (lfurnishingLayer) {
      this.furnishingLayer = lfurnishingLayer;
    }
    const ldecorationLayer = this.map.createLayer(
      "Exterior Decoration",
      tilesets,
      0,
      0,
    );
    if (ldecorationLayer) {
      this.decorationLayer = ldecorationLayer;
    }
    const lbuildingLayer = this.map.createLayer(
      "Exterior Buildings",
      tilesets,
      0,
      0,
    );
    if (lbuildingLayer) {
      this.buildingsLayer = lbuildingLayer;
    }
    const lnpcLayer = this.map.createLayer("NPC", tilesets, 0, 0);
    if (lnpcLayer) {
      this.npcLayer = lnpcLayer;
    }

    const lwallsLayer = this.map.createLayer("Walls", this.tileset, 0, 0);
    if (lwallsLayer) {
      this.wallsLayer = lwallsLayer;
    }
    this.wallsLayer.setCollisionByProperty({ collides: true });
    //this.wallsLayer.visible = false;
    if (DEBUG) {
      this.showDebugWalls();
    }
    this.physics.world.setBounds(
      0,
      0,
      this.wallsLayer.width,
      this.wallsLayer.height,
    );
  }

  private initEnemies(): void {
    const enemiesPoints = gameObjectsToObjectPoints(
      this.map.filterObjects("Enemies", (obj) => obj.name === "EnemyPoint"),
    );
    const enemiesPoints2 = gameObjectsToObjectPoints(
      this.map.filterObjects("Enemies", (obj) => obj.name === "EnemyPoint2"),
    );
    const enemiesPoints3 = gameObjectsToObjectPoints(
      this.map.filterObjects("Enemies", (obj) => obj.name === "EnemyPoint3"),
    );
    this.enemies = enemiesPoints
      .concat(enemiesPoints2)
      .concat(enemiesPoints3)
      .map((enemyPoint) => {
        let enemy: EnemyActor;
        if (enemyPoint.name === "EnemyPoint") {
          enemy = new Enemy(
            this,
            enemyPoint.x,
            enemyPoint.y,
            "tiles_spr",
            this.player1,
            509,
          )
            .setName(enemyPoint.id.toString())
            .setScale(1.5);
        } else if (enemyPoint.name === "EnemyPoint2") {
          enemy = new Enemy2(
            this,
            enemyPoint.x,
            enemyPoint.y,
            "tiles_spr",
            this.player1,
            250,
          )
            .setName(enemyPoint.id.toString())
            .setScale(1.5);
        } else {
          enemy = new Enemy3(
            this,
            enemyPoint.x,
            enemyPoint.y,
            "tiles_spr",
            this.player1,
            750,
          )
            .setName(enemyPoint.id.toString())
            .setScale(1.5);
        }
        return enemy;
      });
    this.physics.add.collider(this.enemies, this.wallsLayer);
    this.physics.add.collider(this.player1, this.enemies, (obj1, _obj2) => {
      (obj1 as Player).animateDamage(1);
      EventBus.emit(EVENTS_NAME.tookDamage);
    });
  }

  private initChests(): void {
    const chestPoints = gameObjectsToObjectPoints(
      this.map.filterObjects("Chests", (obj) => obj.name === "ChestPoint"),
    );
    this.chests = chestPoints.map((chestPoint) =>
      this.physics.add
        .sprite(chestPoint.x, chestPoint.y, "tiles_spr", 595)
        .setScale(1.5),
    );
    this.chests.forEach((chest) => {
      this.physics.add.overlap(this.player1, chest, (_obj1, obj2) => {
        obj2.destroy();
        this.game.events.emit(EVENTS_NAME.chestLoot);
        this.cameras.main.flash();
      });
    });
  }

  private showDebugWalls(): void {
    const debugGraphics = this.add.graphics().setAlpha(0.7);
    this.wallsLayer.renderDebug(debugGraphics, {
      tileColor: null,
      collidingTileColor: new Phaser.Display.Color(243, 234, 48, 255),
    });
  }
}
