import { Box } from "@radix-ui/themes";
import NewsParagraph from "../components/NewsParagraph.tsx";

function Flash3() {
  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: "10px",
      }}
    >
      <NewsParagraph>
        Deepfake Video of Presidential Candidate Causes Political Turmoil
      </NewsParagraph>
      <NewsParagraph>August 6, 2024</NewsParagraph>
      <NewsParagraph>
        WASHINGTON, D.C. (AP) – A deepfake video showing a leading presidential
        candidate engaging in illegal activities has caused a political
        firestorm in the United States. The video, which appeared highly
        authentic, was widely circulated before experts confirmed it was a
        sophisticated AI-generated forgery.
      </NewsParagraph>
      <NewsParagraph>
        The candidate, who has vehemently denied the accusations, is demanding a
        full investigation into the source of the video. "This is a blatant
        attempt to undermine our democratic process," they stated at a press
        conference. "We cannot allow AI to be weaponized in this manner."
      </NewsParagraph>
      <NewsParagraph>
        The incident has led to widespread debate about the implications of AI
        in politics, with experts warning that the technology could be used to
        manipulate elections and sow discord on an unprecedented scale.
      </NewsParagraph>
    </Box>
  );
}

export default Flash3;
