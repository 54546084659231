import { Box, Flex, Section } from "@radix-ui/themes";
import { Navbar } from "../components/Navbar";
import ObfuscatedText from "../components/ObfuscatedText.tsx";
import { Countdown } from "../components/Countdown.tsx";
import { Faction } from "../components/Faction.tsx";
// import ChatDialog from "../components/ChatDialog.tsx";

function Introduction() {
  return (
    <Box>
      <Navbar />
      <Countdown />
      <Faction />
      {/* <ChatDialog /> */}
      <Flex justify={"center"}>
        <Box width={"1000px"}>
          <Section>
            <Box
              style={{
                backgroundColor: "black",
                padding: "20px",
              }}
            >
              <Box
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <ObfuscatedText>This game has a theme.</ObfuscatedText>
              </Box>
              <Box>
                <ObfuscatedText>
                  AI has advanced rapidly and the pace is growing at a rate that
                  multiplies energy and computing availability times
                  advancements in training and assessment efficiency. Though the
                  events reported and described in this game are fiction, it is
                  chilling to consider how reality could come to resemble the
                  fiction. Welcome to REALI7Y OVERRUN.
                </ObfuscatedText>
              </Box>
              <Box
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <ObfuscatedText>
                  The state of the board, current.
                </ObfuscatedText>
              </Box>
              <Box>
                <ObfuscatedText>
                  DefCon is cancelled! No it actually isn't -- but did you know
                  that it almost was? A series of gaffes, mistaken
                  communications, sudden changes of heart from remote
                  administrators and finally the unexpected and unprecedented
                  cancellation of the DefCon venue mere months before the 'con
                  was supposed to begin all combine to paint a picture of an
                  event that very nearly really and truly was cancelled. But we
                  perservered. And unravelling this, and many other questions,
                  is the mission of this game. If we had an adversary that
                  wanted to disrupt our data, our knowledge, our digital
                  connection to each other... where would it choose to attack
                  us? Perhaps at the biggest gathering of those who could defeat
                  it?
                </ObfuscatedText>
              </Box>
              <Box
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                {" "}
                We <span style={{ textDecoration: "line-through" }}>
                  can't
                </span>{" "}
                won't tell you much.
              </Box>
              <Box>
                <ObfuscatedText>
                  Once you create a character and enter the game, you'll notice
                  a few things. (1) You're in a faction: the faction you chose
                  is a loose alliance of villages who will win or lose together
                  in the game. Help your faction! (2) It's a little more
                  diabolical than that, though: you will soon come to understand
                  that all of the factions have a mutual enemy. The greatest
                  glory will come to a single winning faction. However, the
                  factions can also band together to win against the enemy, and
                  even though the individual glory will be much diminished, at
                  least the enemy won't win... (3) The game has a messaging
                  function that is critically important as it is the only
                  official way for players to interact. (4) Explore: the game's
                  win condition will be revealed only by exploring in the game.
                  (5) DefCon is in the game: there are real world puzzles in the
                  game that will require you to do things at DefCon. If you're
                  remote, team up with others in your faction to get those
                  things done.
                </ObfuscatedText>
              </Box>
              <Box
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <ObfuscatedText>Trust is a delicate thing.</ObfuscatedText>
              </Box>
              <Box>
                <ObfuscatedText>
                  One of the most interesting aspects of trust that we know of
                  is that it is founded on the predictive veracity of someone
                  who is to be trusted -- in short, they do what they say they
                  will do, and they are who they say they are. Events confirming
                  this build trust; those that do not, destroy it. Trust cannot
                  be taken lightly in this game.
                </ObfuscatedText>
              </Box>
              <Box
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <ObfuscatedText>
                  If someone tells you that you should follow the rules, you
                  probably shouldn't follow the rules.
                </ObfuscatedText>
              </Box>
              <Box>
                <ObfuscatedText>
                  Not like we're going to tell you the rules, though.
                </ObfuscatedText>
              </Box>
              <Box
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <ObfuscatedText>
                  This is the first iteration of the game.
                </ObfuscatedText>
              </Box>
              <Box>
                <ObfuscatedText>
                  This is the first time we've run this game and the expectation
                  is that this storyline will continue in following years as
                  things unfold.
                </ObfuscatedText>
              </Box>
            </Box>
          </Section>
        </Box>
      </Flex>
    </Box>
  );
}

export default Introduction;
