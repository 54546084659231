import { Box, Flex } from "@radix-ui/themes";
import { Navbar } from "../components/Navbar";
import { useContext, useState } from "react";
import { AccountContext, PlayerContext, WebSocketContext } from "../App.tsx";
import { createAccount, createPlayer, loginAccount } from "../api.ts";
import { Countdown } from "../components/Countdown.tsx";
import * as Form from "@radix-ui/react-form";
import { Faction } from "../components/Faction.tsx";
// import ChatDialog from "../components/ChatDialog.tsx";

function Account() {
  const [account] = useContext(AccountContext);
  const [player] = useContext(PlayerContext);
  const websocket = useContext(WebSocketContext);
  const [created, setCreated] = useState(false);

  const createNewAccount = (username: string, password: string) => {
    if (websocket) {
      createAccount(websocket, username, password);
      setCreated(true);
    } else {
      console.log("websocket is null");
    }
  };

  const createNewPlayer = (name: string, faction: string, tagline: string) => {
    if (websocket) {
      createPlayer(websocket, name, parseInt(faction), tagline);
    } else {
      console.log("websocket is null");
    }
  };

  const login = (username: string, password: string) => {
    if (websocket) {
      loginAccount(websocket, username, password);
    } else {
      console.log("websocket is null");
    }
  };

  return (
    <Box>
      <Navbar />
      <Countdown />
      <Faction />
      {/* <ChatDialog /> */}
      <Flex justify={"center"}>
        <Box width={"1000px"} style={{ marginTop: "50px" }}>
          <Box
            style={{
              backgroundColor: "black",
              padding: "20px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {!account && (
              <Box>
                <Box
                  style={{
                    margin: "10px",
                    padding: "10px",
                    backgroundColor: "#333333",
                  }}
                >
                  <Box
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Form.Root
                      className="FormRoot"
                      onSubmit={(event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const username = formData.get("username") as string;
                        const password = formData.get("password") as string;
                        login(username, password);
                      }}
                    >
                      <Form.Field className="FormField" name="username">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Label className="FormLabel">
                            Username
                          </Form.Label>
                          <Form.Message
                            className="FormMessage"
                            match="valueMissing"
                          >
                            Please enter your username
                          </Form.Message>
                        </div>
                        <Form.Control asChild>
                          <input
                            className="Input"
                            type="username"
                            name={"username"}
                            required
                          />
                        </Form.Control>
                      </Form.Field>
                      <Form.Field className="FormField" name="password">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Label className="FormLabel">
                            Password
                          </Form.Label>
                          <Form.Message
                            className="FormMessage"
                            match="valueMissing"
                          >
                            Please enter your password
                          </Form.Message>
                        </div>
                        <Form.Control asChild>
                          <input
                            className="Input"
                            type="password"
                            name={"password"}
                            required
                          />
                        </Form.Control>
                      </Form.Field>
                      <Form.Submit asChild>
                        <button
                          className="Button"
                          style={{
                            marginTop: 10,
                            border: "1px solid white",
                            padding: "10px",
                            cursor: "pointer",
                            backgroundColor: "#300",
                          }}
                        >
                          Log in
                        </button>
                      </Form.Submit>
                    </Form.Root>
                  </Box>
                </Box>
                {!created && (
                  <Box
                    style={{
                      margin: "10px",
                      padding: "10px",
                      backgroundColor: "#333333",
                    }}
                  >
                    <Box
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Form.Root
                        className="FormRoot"
                        onSubmit={(event) => {
                          event.preventDefault();
                          const formData = new FormData(event.currentTarget);
                          const username = formData.get("username") as string;
                          const password = formData.get("password") as string;
                          createNewAccount(username, password);
                        }}
                      >
                        <Form.Field className="FormField" name="username">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              justifyContent: "space-between",
                            }}
                          >
                            <Form.Label className="FormLabel">
                              Username
                            </Form.Label>
                            <Form.Message
                              className="FormMessage"
                              match="valueMissing"
                            >
                              Please enter your username
                            </Form.Message>
                          </div>
                          <Form.Control asChild>
                            <input
                              className="Input"
                              type="username"
                              name={"username"}
                              required
                            />
                          </Form.Control>
                        </Form.Field>
                        <Form.Field className="FormField" name="password">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              justifyContent: "space-between",
                            }}
                          >
                            <Form.Label className="FormLabel">
                              Password
                            </Form.Label>
                            <Form.Message
                              className="FormMessage"
                              match="valueMissing"
                            >
                              Please enter your password
                            </Form.Message>
                          </div>
                          <Form.Control asChild>
                            <input
                              className="Input"
                              type="password"
                              name={"password"}
                              required
                            />
                          </Form.Control>
                        </Form.Field>
                        <Form.Submit asChild>
                          <button
                            className="Button"
                            style={{
                              marginTop: 10,
                              border: "1px solid white",
                              padding: "10px",
                              cursor: "pointer",
                              backgroundColor: "#300",
                            }}
                          >
                            Create account
                          </button>
                        </Form.Submit>
                      </Form.Root>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {account && !player && (
              <Box
                style={{
                  margin: "10px",
                  padding: "10px",
                  backgroundColor: "#333333",
                }}
              >
                <Box
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  <Form.Root
                    className="FormRoot"
                    onSubmit={(event) => {
                      event.preventDefault();
                      const formData = new FormData(event.currentTarget);
                      const name = formData.get("name") as string;
                      const faction = formData.get("faction") as string;
                      const tagline = formData.get("tagline") as string;
                      createNewPlayer(name, faction, tagline);
                    }}
                  >
                    <Form.Field className="FormField" name="name">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Label className="FormLabel">Name</Form.Label>
                        <Form.Message
                          className="FormMessage"
                          match="valueMissing"
                        >
                          Please enter your name
                        </Form.Message>
                      </div>
                      <Form.Control asChild>
                        <input
                          className="Input"
                          type="name"
                          name={"name"}
                          required
                        />
                      </Form.Control>
                    </Form.Field>
                    <Form.Field className="FormField" name="tagline">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Label className="FormLabel">Tagline</Form.Label>
                        <Form.Message
                          className="FormMessage"
                          match="valueMissing"
                        >
                          Please enter your tagline
                        </Form.Message>
                      </div>
                      <Form.Control asChild>
                        <input
                          className="Input"
                          type="tagline"
                          name={"tagline"}
                          required
                        />
                      </Form.Control>
                    </Form.Field>
                    <Form.Field className="FormField" name="faction">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Label className="FormLabel">Faction</Form.Label>
                        <Form.Message
                          className="FormMessage"
                          match="valueMissing"
                        >
                          Please enter your faction
                        </Form.Message>
                      </div>
                      <Form.Control asChild>
                        <select name={"faction"}>
                          <option value="1">AI and Data</option>…
                          <option value="2">Attack and Offense</option>…
                          <option value="3">Over the Air</option>…
                          <option value="4">People</option>…
                          <option value="5">Physical</option>…
                          <option value="6">Policy and Professionals</option>…
                          <option value="7">Security and Defense</option>…
                        </select>
                      </Form.Control>
                    </Form.Field>
                    <Form.Submit asChild>
                      <button
                        className="Button"
                        style={{
                          marginTop: 10,
                          border: "1px solid white",
                          padding: "10px",
                          cursor: "pointer",
                          backgroundColor: "#300",
                        }}
                      >
                        Create player
                      </button>
                    </Form.Submit>
                  </Form.Root>
                </Box>
              </Box>
            )}
            {account && player && (
              <Box
                style={{
                  margin: "10px",
                  padding: "10px",
                  backgroundColor: "#333333",
                }}
              >
                <p>
                  You are logged in as {player.account_id}/{player.player_id}{" "}
                  and your player name is {player.name} with tagline{" "}
                  {player.tagline}. Please proceed to the game.
                </p>
              </Box>
            )}
          </Box>

          <Box style={{ margin: "10px" }}>
            <Box style={{ fontSize: "20px", fontWeight: "bold" }}>
              Factions and their villages
            </Box>
            <Box>
              <p style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>
                  AI & Data [faction_id: 1]
                </span>
                : AI Village, AIxCC, Data Duplication Village, XR Village
              </p>
              <p style={{ display: "block", marginBottom: "10px" }}>
                The AI & Data faction is a melting pot of innovation and
                analytical prowess, drawing data scientists, AI engineers, and
                researchers dedicated to pushing the boundaries of artificial
                intelligence and data management. This faction thrives on
                curiosity and collaboration, exploring everything from deep
                learning and neural networks to data duplication and extended
                reality (XR). The community spirit is one of relentless pursuit
                of knowledge and ethical AI deployment, with a focus on
                transforming data into actionable insights and creating
                intelligent systems that can revolutionize industries.
              </p>

              <p style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>
                  Attack and Offense [faction_id: 2]
                </span>
                : Adversary Village, Bug Bounty Village, Packet Hacking Village,
                Quantum Village, Red Team Village
              </p>
              <p style={{ display: "block", marginBottom: "10px" }}>
                The Attack and Offense faction is the domain of cybersecurity
                warriors and ethical hackers who specialize in uncovering
                vulnerabilities and strengthening security defenses. This
                faction attracts red teamers, penetration testers, and bug
                bounty hunters who revel in the challenge of offensive security.
                The atmosphere is competitive and daring, with members
                constantly honing their skills to outsmart adversaries. From
                quantum hacking to packet sniffing, this faction embodies the
                relentless pursuit of excellence in the art of cyber offense.{" "}
              </p>

              <p style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>
                  Over the Air [faction_id: 3]
                </span>
                : Cloud Village, HAM Radio Village, IOT Village, Radio Frequency
                Village
              </p>
              <p style={{ display: "block", marginBottom: "10px" }}>
                The Over the Air faction is where wireless technology and
                communication enthusiasts converge. It attracts a diverse group
                of cloud security professionals, HAM radio operators, IoT
                experts, and RF engineers. The spirit of this faction is one of
                exploration and innovation, with a focus on securing and
                advancing wireless communication technologies. Whether it's
                safeguarding cloud environments or experimenting with radio
                frequencies, this faction is at the forefront of the
                ever-evolving landscape of over-the-air technologies.{" "}
              </p>

              <p style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>
                  People [faction_id: 4]
                </span>
                : Biohacking Village, Blacks in Cyber Village, Social
                Engineering Village
              </p>
              <p style={{ display: "block", marginBottom: "10px" }}>
                The People faction is dedicated to the human aspects of
                cybersecurity and technology. This faction attracts social
                engineers, biohackers, and advocates for diversity in cyber. The
                community spirit is supportive and inclusive, focusing on the
                psychological and social dimensions of security. From biohacking
                and social engineering to promoting diversity within the
                cybersecurity field, this faction is driven by the belief that
                people are at the heart of technology and security.{" "}
              </p>

              <p style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>
                  Physical [faction_id: 5]
                </span>
                : Telecom Village, Car Hacking Village, Embedded Village,
                HHV/SSV Lockpicking Village, Physical Security Village, Tamper
                Evident Village
              </p>
              <p style={{ display: "block", marginBottom: "10px" }}>
                The Physical faction focuses on the tangible aspects of
                security, encompassing everything from lockpicking and car
                hacking to securing telecommunications and embedded systems.
                This faction draws engineers, security experts, and hands-on
                enthusiasts who are passionate about physical security measures.
                The vibe is practical and skill-based, with members dedicated to
                understanding and protecting the physical components of our
                interconnected world. From tamper-evident technologies to
                physical penetration testing, this faction covers the broad
                spectrum of physical security.
              </p>

              <p style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>
                  Policy and Professionals [faction_id: 6]
                </span>
                : Aerospace Village, ICS Village, Policy Village, Recon Village,
                Voting Village
              </p>
              <p style={{ display: "block", marginBottom: "10px" }}>
                The Policy and Professionals faction bridges the gap between
                technology and governance, attracting policymakers, legal
                experts, and security professionals. This faction is strategic
                and legislative, focusing on shaping cybersecurity laws,
                policies, and regulations. From aerospace security to election
                integrity, members of this faction work towards creating
                frameworks that protect critical infrastructure and ensure safe
                and fair technological advancements. The spirit is one of
                advocacy and foresight, striving to navigate the complexities of
                cybersecurity policy in an ever-evolving digital landscape.
              </p>

              <p style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>
                  Security and Defense [faction_id: 7]
                </span>
                : AppSec, Blue Team Village, Crypto Privacy Village, Payment
                Village
              </p>
              <p style={{ display: "block" }}>
                The Security and Defense faction is the bastion of defensive
                tactics and protective measures, attracting blue teamers, app
                security experts, and cryptographers. This faction is vigilant
                and collaborative, focused on safeguarding systems,
                applications, and data from cyber threats. The community vibe is
                proactive and resilient, with members dedicated to enhancing
                security through rigorous testing, incident response, and
                advanced cryptographic techniques. From securing financial
                transactions to protecting privacy, this faction embodies the
                essence of cybersecurity defense.{" "}
              </p>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default Account;
