import { Box, Flex, Section } from "@radix-ui/themes";
import { Navbar } from "../components/Navbar";
import ObfuscatedText from "../components/ObfuscatedText.tsx";
import { Countdown } from "../components/Countdown.tsx";
import { Faction } from "../components/Faction.tsx";
// import ChatDialog from "../components/ChatDialog.tsx";

function Odds() {
  return (
    <Box>
      <Navbar />
      <Countdown />
      <Faction />
      {/* <ChatDialog /> */}
      <Flex justify={"center"}>
        <Box width={"1000px"}>
          <Section>
            <Box
              style={{
                backgroundColor: "black",
                padding: "20px",
              }}
            >
              <Box
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                <ObfuscatedText>We are known.</ObfuscatedText>
              </Box>
              <Box
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                <ObfuscatedText>The dungeon masters</ObfuscatedText>
              </Box>
              <Box>
                <ObfuscatedText>
                  We're cmdc0de, 0a, and WildSamosa and we're excited to bring
                  you a new game. We're hackers, tinkerers, makers and DefCon
                  junkies whose aim is to provoke thought, curiosity, and
                  hopefully quite a bit of hilarity as we do our best to
                  entertain and challenge you.
                </ObfuscatedText>
              </Box>
              <Box
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                {" "}
                <ObfuscatedText>Inspiration</ObfuscatedText>
              </Box>
              <Box>
                <ObfuscatedText>
                  We've drawn inspiration from so many different sources that it
                  is difficult to list them all. Our recent work with the DefCon
                  DarkNet game was a journey of a lifetime and we met and got to
                  know so many people and had such epic times -- this spirit
                  infuses what we want to bring to DefCon. We have long
                  appreciated the works of William Gibson, Neil Stephenson, and
                  Daniel Suarez and others and you can find those themes of both
                  hope and dystopianism in our work. We're coders, game
                  developers, cipher builders, crafters, social hackers and
                  security enthusiasts. Everything from silly trivial puzzles to
                  pop culture references are fair game. Movies such as Wargames
                  and Hackers fuel us. As gamers, we're read up on everything
                  from classic board games like Clue and Catan to modern social
                  games like One Night Werewolf to online strategy and
                  competitive games like League of Legends, StarCraft and Don't
                  Starve Together. We're chess players and Reggie Watts lovers.
                  We're diabolical game mechanic designers and sharp witted
                  social engineeers. We hope you'll find all of this in our
                  game, and more.
                </ObfuscatedText>
              </Box>
            </Box>
          </Section>
        </Box>
      </Flex>
    </Box>
  );
}

export default Odds;
