import { Box, Flex, Section } from "@radix-ui/themes";
import { Navbar } from "../components/Navbar";
import { Countdown } from "../components/Countdown.tsx";
import { Faction } from "../components/Faction.tsx";
import { useContext, useEffect, useState } from "react";
import { FactionStruct, triggerLeaderboardMessages } from "../api.ts";
import { WebSocketContext } from "../App.tsx";
// import ChatDialog from "../components/ChatDialog.tsx";

interface PlayerScoreBoard {
  player_id: number;
  name: string;
  tagline: string;
  score: number;
}

function Leaderboard() {
  const websocket = useContext(WebSocketContext);
  const [factionDict, setFactionDict] = useState<Record<string, FactionStruct>>(
    {},
  );
  const [playerScoreboard, setPlayerScoreboard] = useState<PlayerScoreBoard[]>(
    [],
  );

  useEffect(() => {
    const scoreBoardInterval = setInterval(() => {
      const playerBoard = localStorage.getItem("playerScoreboard");
      if (playerBoard) {
        const playerBoardParsed = JSON.parse(playerBoard);
        if (playerBoardParsed) {
          console.log(playerBoardParsed.score_board);
          setPlayerScoreboard(playerBoardParsed.score_board);
        }
      }
    }, 1000 * 10);
    return () => {
      clearInterval(scoreBoardInterval);
    };
  }, []);

  useEffect(() => {
    const factionCheckInterval = setInterval(() => {
      console.log("checking for new localstorage factions");
      const localFactions = localStorage.getItem("factionList");
      if (localFactions) {
        const localFactionsParsed = JSON.parse(localFactions);
        if (localFactionsParsed) {
          setFactionDict(localFactionsParsed);
        }
      }
    }, 2000);
    return () => {
      clearInterval(factionCheckInterval);
    };
  }, []);

  useEffect(() => {
    if (websocket) {
      setTimeout(() => {
        triggerLeaderboardMessages(websocket);
      }, 2000);
    }
    return () => {
      console.log("umounting leaderboard");
    };
  }, [websocket]);
  return (
    <Box>
      <Navbar />
      <Countdown />
      <Faction />
      {/* <ChatDialog /> */}
      <Flex justify={"center"}>
        <Box width={"1000px"}>
          <Section>
            <Box
              style={{
                backgroundColor: "black",
                padding: "20px",
              }}
            >
              <Box
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                <span
                  style={{
                    display: "block",
                    margin: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Faction leaderboard
                </span>
                <ul>
                  {Object.keys(factionDict).map((faction: string) => (
                    <li key={factionDict[faction].faction_id}>
                      {factionDict[faction].faction_name}:{" "}
                      {factionDict[faction].inv.game_item_bag.length}
                    </li>
                  ))}
                </ul>
                <p></p>
                <span
                  style={{
                    display: "block",
                    margin: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Player leaderboard
                </span>
                <ul>
                  {playerScoreboard.map((playerScore) => (
                    <li key={playerScore.player_id}>
                      {playerScore.name}: {playerScore.score}
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
          </Section>
        </Box>
      </Flex>
    </Box>
  );
}

export default Leaderboard;
