import { Box } from "@radix-ui/themes";
import NewsParagraph from "../components/NewsParagraph.tsx";

function Flash2() {
  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: "10px",
      }}
    >
      <NewsParagraph>
        Fabricated Climate Crisis Report Sparks International Uproar
      </NewsParagraph>
      <NewsParagraph>August 5, 2024</NewsParagraph>
      <NewsParagraph>
        GENEVA, SWITZERLAND (AP) – The release of a highly detailed, yet
        entirely fabricated climate crisis report has sparked international
        uproar and confusion. The report, allegedly from the United Nations,
        claimed that a series of catastrophic environmental events were
        imminent, leading to panic and mass protests in several countries.
      </NewsParagraph>
      <NewsParagraph>
        Upon investigation, it was revealed that the report was generated by an
        AI using advanced language models to create realistic scientific data
        and projections. "The level of detail and plausibility in the report is
        frightening," said Dr. Samuel Greene, a climate scientist at the
        University of Cambridge. "It took us days to verify that it was a hoax."
      </NewsParagraph>
      <NewsParagraph>
        The incident has raised concerns about the vulnerability of even the
        most respected institutions to AI-generated misinformation. Calls for
        stronger verification mechanisms and international cooperation to combat
        such threats have intensified in the wake of the scandal.
      </NewsParagraph>
    </Box>
  );
}

export default Flash2;
