import { Box, Callout } from "@radix-ui/themes";
import { CountdownTimerIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";

const Countdown = () => {
  const terminus = new Date("2024-08-11T19:00:00Z").getTime();
  const [dd, setDd] = useState("00");
  const [hh, setHh] = useState("00");
  const [mm, setMm] = useState("00");
  const [ss, setSs] = useState("00");
  const [ms, setMs] = useState("000");

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = terminus - now;
      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const milliseconds = Math.floor(distance % 1000);
        setDd(days.toString().padStart(2, "0"));
        setHh(hours.toString().padStart(2, "0"));
        setMm(minutes.toString().padStart(2, "0"));
        setSs(seconds.toString().padStart(2, "0"));
        setMs(milliseconds.toString().padStart(3, "0"));
      }
    }, 91);
    return () => {
      clearInterval(timerInterval);
    };
  }, [terminus]);

  return (
    <Box
      style={{
        display: "inline-block",
        position: "fixed",
        right: "5px",
        top: "5px",
        width: "200px",
        opacity: "0.70",
      }}
    >
      <Callout.Root
        size={"1"}
        variant={"outline"}
        color={"red"}
        style={{ backgroundColor: "black", padding: "9px" }}
      >
        <Callout.Icon>
          <CountdownTimerIcon />
        </Callout.Icon>
        <Callout.Text>
          {dd}D{hh}H{mm}M{ss}S{ms}ms
        </Callout.Text>
      </Callout.Root>
    </Box>
  );
};

export { Countdown };
