import { Scene, Tilemaps } from "phaser";
import { EventBus } from "../../EventBus.ts";
import { Player } from "../../classes/player.ts";
import { Mrpas } from "mrpas";
import { EVENTS_NAME } from "../../consts.ts";
import { gameObjectsToObjectPoints } from "../../helpers/gameobject-to-object-point.ts";
import Vector2 = Phaser.Math.Vector2;

const SIGHT_RADIUS = 15; // in tiles
const OFF_TINT = 0x404040;
const ON_TINT = 0xffffff;
export const FOV = false;
const ALLOW_ZOOM = false;

export class BaseScene extends Scene {
  player1!: Player;
  map!: Tilemaps.Tilemap;
  wallsLayer!: Tilemaps.TilemapLayer;
  groundLayer!: Tilemaps.TilemapLayer;
  furnishingLayer!: Tilemaps.TilemapLayer;
  decorationLayer!: Tilemaps.TilemapLayer;
  buildingsLayer!: Tilemaps.TilemapLayer;
  npcLayer!: Tilemaps.TilemapLayer;
  fov?: Mrpas;
  mapName = "Tutorial level";
  challengeNameActivated: string = "";
  target: Vector2;

  initCamera(): void {
    this.cameras.main.setSize(this.game.scale.width, this.game.scale.height);
    this.cameras.main.startFollow(this.player1, true, 0.09, 0.09);
    this.cameras.main.setZoom(2);

    if (ALLOW_ZOOM) {
      this.input.on(
        "wheel",
        (
          _pointer: Phaser.Input.Pointer,
          _gameObjects: Phaser.GameObjects.GameObject[],
          _deltaX: number,
          deltaY: number,
          _deltaZ: number,
        ) => {
          const newZoom =
            this.cameras.main.zoom - this.cameras.main.zoom * 0.001 * deltaY;
          this.cameras.main.zoom = Phaser.Math.Clamp(newZoom, 0.25, 2);
        },
      );
    }

    this.target = new Vector2();
    this.input.on("pointerdown", (pointer: Phaser.Input.Pointer) => {
      // Get the WORLD x and y position of the pointer
      const { worldX, worldY } = pointer;

      // Assign the world x and y to our vector
      this.target.x = worldX;
      this.target.y = worldY;

      // Start moving our cat towards the target
      this.physics.moveToObject(this.player1, this.target, 110);
    });
  }

  computeFOV(sight_radius: number = SIGHT_RADIUS) {
    if (
      !this.fov ||
      !this.map ||
      !this.groundLayer ||
      !this.wallsLayer ||
      !this.player1
    ) {
      return;
    }

    // get camera view bounds
    const camera = this.cameras.main;
    const lx = this.map.worldToTileX(camera.worldView.x) || 0;
    const ly = this.map.worldToTileY(camera.worldView.y) || 0;
    const lw = this.map.worldToTileX(camera.worldView.width) || 0;
    const lh = this.map.worldToTileX(camera.worldView.height) || 0;
    const bounds = new Phaser.Geom.Rectangle(lx - 1, ly - 1, lw + 2, lh + 3);

    // set all tiles within camera view to invisible
    for (let y = bounds.y; y < bounds.y + bounds.height; y++) {
      for (let x = bounds.x; x < bounds.x + bounds.width; x++) {
        if (y < 0 || y >= this.map.height || x < 0 || x >= this.map.width) {
          continue;
        }
        const tile = this.groundLayer.getTileAt(x, y);
        if (tile) {
          tile.alpha = 1;
          tile.tint = OFF_TINT;
        }
        const wtile = this.wallsLayer.getTileAt(x, y);
        if (wtile) {
          wtile.alpha = 1;
          wtile.tint = OFF_TINT;
        }
        if (this.furnishingLayer) {
          const ftile = this.furnishingLayer.getTileAt(x, y);
          if (ftile) {
            ftile.alpha = 1;
            ftile.tint = OFF_TINT;
          }
        }
        if (this.decorationLayer) {
          const dtile = this.decorationLayer.getTileAt(x, y);
          if (dtile) {
            dtile.alpha = 1;
            dtile.tint = OFF_TINT;
          }
        }
        if (this.buildingsLayer) {
          const btile = this.buildingsLayer.getTileAt(x, y);
          if (btile) {
            btile.alpha = 1;
            btile.tint = OFF_TINT;
          }
        }
      }
    }

    // compute fov from player's position
    const px = this.map.worldToTileX(this.player1.x) || 0;
    const py = this.map.worldToTileY(this.player1.y) || 0;
    // compute fov from player's position
    this.fov.compute(
      px,
      py,
      sight_radius,
      (x, y) => {
        const tile = this.groundLayer!.getTileAt(x, y);
        const wtile = this.wallsLayer!.getTileAt(x, y);
        let ftile: Tilemaps.Tile | null = null;
        let dtile: Tilemaps.Tile | null = null;
        let bxtile: Tilemaps.Tile | null = null;
        if (this.furnishingLayer) {
          ftile = this.furnishingLayer.getTileAt(x, y);
        }
        if (this.decorationLayer) {
          dtile = this.furnishingLayer.getTileAt(x, y);
        }
        if (this.buildingsLayer) {
          bxtile = this.furnishingLayer.getTileAt(x, y);
        }
        let btile = false;
        let bwtile = false;
        let bftile = false;
        let bdtile = false;
        let bbtile = false;
        if (tile) {
          btile = tile.tint === ON_TINT;
        }
        if (wtile) {
          bwtile = wtile.tint === ON_TINT;
        }
        if (ftile) {
          bftile = ftile.tint === ON_TINT;
        }
        if (dtile) {
          bdtile = dtile.tint === ON_TINT;
        }
        if (bxtile) {
          bbtile = bxtile.tint === ON_TINT;
        }
        return btile || bwtile || bftile || bdtile || bbtile;
      },
      (x, y) => {
        const d = Phaser.Math.Distance.Between(py, px, y, x);
        const alpha = Math.min(2 - d / sight_radius - 0.5, 1);
        const tile = this.groundLayer!.getTileAt(x, y);
        const wtile = this.wallsLayer!.getTileAt(x, y);
        if (tile) {
          tile.alpha = alpha;
          tile.tint = ON_TINT;
        }
        if (wtile) {
          wtile.alpha = alpha;
          wtile.tint = ON_TINT;
        }
      },
    );
  }

  initStorylines(): void {
    const storylines = gameObjectsToObjectPoints(
      this.map.filterObjects("Storylines", (obj) => obj.name === "Storyline"),
    );
    const storylineList = storylines.map((storyline) => {
      return this.physics.add
        .sprite(
          storyline.x + storyline.width / 2,
          storyline.y + storyline.height / 2,
          "tiles_spr",
          595,
        )
        .setSize(storyline.width, storyline.height)
        .setAlpha(0)
        .setName(
          `${storyline.properties[1].value}:${storyline.properties[0].value}`,
        );
    });
    storylineList.forEach((storyline) => {
      this.physics.add.overlap(this.player1, storyline, (_obj1, obj2) => {
        if (!(obj2 instanceof Tilemaps.Tile)) {
          EventBus.emit(EVENTS_NAME.showStoryline, obj2.name);
        }
      });
    });
  }

  initChallenges(): void {
    const challenges = gameObjectsToObjectPoints(
      this.map.filterObjects("Challenges", (obj) => obj.name === "Challenge"),
    );
    const challengeList = challenges.map((challenge) => {
      return this.physics.add
        .sprite(
          challenge.x + challenge.width / 2,
          challenge.y + challenge.height / 2,
          "tiles_spr",
          595,
        )
        .setSize(challenge.width, challenge.height)
        .setAlpha(0)
        .setName(`${challenge.properties[0].value}`);
    });
    challengeList.forEach((challenge) => {
      this.physics.add.overlap(this.player1, challenge, (_obj1, obj2) => {
        if (!(obj2 instanceof Tilemaps.Tile)) {
          if (this.challengeNameActivated !== obj2.name) {
            this.challengeNameActivated = obj2.name;
            EventBus.emit(EVENTS_NAME.showChallenge, obj2.name);
          }
        }
      });
    });
  }

  initZones(): void {
    const zoneAreas = gameObjectsToObjectPoints(
      this.map.filterObjects("Zones", (obj) => obj.name === "ZoneArea"),
    );
    const zoneList = zoneAreas.map((zone) => {
      return this.physics.add
        .sprite(
          zone.x + zone.width / 2,
          zone.y + zone.height / 2,
          "tiles_spr",
          595,
        )
        .setSize(zone.width, zone.height)
        .setAlpha(0)
        .setName(`${this.mapName} - ${zone.properties[0].value}`);
    });
    zoneList.forEach((zone) => {
      this.physics.add.overlap(this.player1, zone, (_obj1, obj2) => {
        if (!(obj2 instanceof Tilemaps.Tile)) {
          EventBus.emit(EVENTS_NAME.zoneEnter, obj2.name);
        }
      });
    });
  }

  globalUpdate(): void {
    this.player1.update();

    if (this.player1 && this.player1.body && this.target.x !== 0) {
      // Calculate it's distance to the target
      const d = Phaser.Math.Distance.Between(
        this.player1.x,
        this.player1.y,
        this.target.x,
        this.target.y,
      );
      // If it's close enough,
      if (d < 10) {
        // Reset it's body so it stops
        this.player1.body.reset(this.target.x, this.target.y);
        this.player1.setVelocity(0);
        this.target.x = 0;
        this.target.y = 0;
      }
    }

    if (
      this.player1.body?.touching.none &&
      !this.player1.body?.wasTouching.none
    ) {
      EventBus.emit(EVENTS_NAME.clearGameUi);
    }
    if (FOV) {
      this.computeFOV();
    }
  }
}

export class Level0 extends BaseScene {
  constructor() {
    super("Level0");
    this.mapName = "Tutorial level";
  }

  update(): void {
    this.globalUpdate();
    localStorage.setItem(
      "playerLocationLevel0",
      JSON.stringify([this.player1.x, this.player1.y]),
    );
  }

  create(): void {
    this.initMap();
    const defaultPlayerStartLocation = [100, 100];
    const playerStartLocation = localStorage.getItem("playerLocationLevel0")
      ? JSON.parse(localStorage.getItem("playerLocationLevel0")!)
      : defaultPlayerStartLocation;
    this.player1 = new Player(
      this,
      playerStartLocation[0],
      playerStartLocation[1],
    );
    this.physics.add.collider(this.player1, this.wallsLayer);
    this.initCamera();
    this.fov = new Mrpas(this.map.width, this.map.height, (x, y) => {
      const tile = this.wallsLayer!.getTileAt(x, y);
      return !tile || !tile.collides;
    });
    this.initTeleporters();
    this.initZones();
    this.initStorylines();
    this.initChallenges();
    EventBus.emit(EVENTS_NAME.currentSceneReady, this);
  }

  changeScene() {
    this.scene.stop("Level0UIScene");
    this.scene.start("Level1UIScene");
    this.scene.start("Level1");
    this.sound.stopAll();
  }

  initTeleporters(): void {
    const teleporters = gameObjectsToObjectPoints(
      this.map.filterObjects("Zones", (obj) => obj.name === "Teleporter"),
    );
    const teleporterList = teleporters.map((teleporter) => {
      return this.physics.add
        .sprite(
          teleporter.x + teleporter.width / 2,
          teleporter.y + teleporter.height / 2,
          "tiles_spr",
          595,
        )
        .setSize(teleporter.width, teleporter.height)
        .setAlpha(0);
    });
    teleporterList.forEach((teleporter) => {
      this.physics.add.overlap(this.player1, teleporter, (_obj1, _obj2) => {
        this.changeScene();
      });
    });
  }

  initMap(): void {
    this.map = this.make.tilemap({
      key: "layer0",
      tileWidth: 16,
      tileHeight: 16,
    });

    const ltileset = this.map.addTilesetImage("conf-hall", "tilesInterior");
    const ltilesetConf = this.map.addTilesetImage("tilesets", "tilesModern");
    const tilesets = [];
    if (ltileset) {
      tilesets.push(ltileset);
    }
    if (ltilesetConf) {
      tilesets.push(ltilesetConf);
    }

    const lgroundLayer = this.map.createLayer("Ground", tilesets, 0, 0);
    if (lgroundLayer) {
      this.groundLayer = lgroundLayer;
    }
    const lwallsLayer = this.map.createLayer("Walls", tilesets, 0, 0);
    if (lwallsLayer) {
      this.wallsLayer = lwallsLayer;
    }
    this.wallsLayer.setCollisionByProperty({ collides: true });
    //this.wallsLayer.visible = false;

    // this.showDebugWalls();
    this.physics.world.setBounds(
      0,
      0,
      this.wallsLayer.width,
      this.wallsLayer.height,
    );
  }
}
