import { Box } from "@radix-ui/themes";
import NewsParagraph from "../components/NewsParagraph.tsx";

function Long4() {
  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: "10px",
      }}
    >
      <NewsParagraph>
        AI-Created Art Auction Causes Uproar in the Art World
      </NewsParagraph>
      <NewsParagraph>August 8, 2024</NewsParagraph>
      <NewsParagraph>
        NEW YORK, NY (AP) – The art world is in turmoil after an AI-generated
        artwork sold for a record-breaking $50 million at a high-profile
        auction, only to be revealed as a forgery. The piece, which was
        presented as the work of a renowned but reclusive artist, captivated
        collectors and critics alike before the truth came to light.
      </NewsParagraph>
      <NewsParagraph>
        The revelation came when the artist himself, who had been living in
        seclusion, publicly denounced the piece as a fake. "This is not my work.
        I had no part in creating it," he stated in a rare public appearance.
        The auction house, which had authenticated the piece, is now facing
        intense scrutiny and legal challenges.
      </NewsParagraph>
      <NewsParagraph>
        Digital forensics confirmed that the artwork was created using an
        advanced AI capable of mimicking the artist's unique style. "The
        AI-generated forgery was incredibly convincing," said art historian Dr.
        William Grant. "It raises serious questions about the authenticity and
        provenance of art in the digital age."
      </NewsParagraph>
      <NewsParagraph>
        The incident has sent shockwaves through the art community, with many
        fearing that AI-generated forgeries could undermine the market's
        integrity. "Collectors rely on authenticity and provenance. This
        incident has shaken that trust to its core," said gallery owner Sarah
        Collins.
      </NewsParagraph>
      <NewsParagraph>
        Art institutions and experts are now calling for the development of new
        methods to verify the authenticity of artworks. "We need advanced tools
        and protocols to ensure that AI cannot so easily deceive us," said Dr.
        Emily Zhang, a leading expert in art conservation and technology.
      </NewsParagraph>
      <NewsParagraph>
        As the art world grapples with this crisis, the broader implications of
        AI's ability to manipulate and deceive are becoming increasingly
        apparent. The need for vigilance and innovation in the face of these
        challenges has never been more urgent.
      </NewsParagraph>
    </Box>
  );
}

export default Long4;
