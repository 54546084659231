import { Box, Flex, Section } from "@radix-ui/themes";
import { Navbar } from "../components/Navbar";
import { Countdown } from "../components/Countdown.tsx";
import { Faction } from "../components/Faction.tsx";
// import ChatDialog from "../components/ChatDialog.tsx";

function Home() {
  return (
    <Box>
      <Navbar />
      <Countdown />
      <Faction />
      {/* <ChatDialog /> */}
      <Section>
        <Flex justify={"center"}>
          <img src={"assets/reali7y-overrun-logo.png"} alt={"logo"} />
          <img
            src={"assets/qr.png"}
            alt={"logo"}
            width={200}
            height={200}
            style={{ position: "fixed", left: "10px", top: "10px" }}
          />
          <span
            style={{
              fontFamily: "Courier",
              fontSize: "50px",
              position: "fixed",
              top: "890px",
              left: "550px",
            }}
          >
            Shall we play a game?
          </span>
        </Flex>{" "}
      </Section>
    </Box>
  );
}

export default Home;
