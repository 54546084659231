import { Box } from "@radix-ui/themes";
import NewsParagraph from "../components/NewsParagraph.tsx";

function Long1() {
  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: "10px",
      }}
    >
      <NewsParagraph>
        AI Deepfake Sparks International Celebrity Scandal
      </NewsParagraph>
      <NewsParagraph>August 5, 2024</NewsParagraph>
      <NewsParagraph>
        LOS ANGELES, CA (AP) – In a sensational turn of events, a deepfake video
        of renowned actor James Harrington engaging in illicit activities has
        taken the internet by storm, throwing his career and personal life into
        disarray. The video, which appears highly authentic, has been viewed
        millions of times and has become a trending topic across social media
        platforms.
      </NewsParagraph>
      <NewsParagraph>
        Despite Harrington's vehement denials and a plea for public
        understanding, the damage has been done. "This is a complete
        fabrication. I have never engaged in such behavior," Harrington said in
        a tearful press conference. However, the convincing nature of the video
        has left many fans and industry insiders questioning his integrity.
      </NewsParagraph>
      <NewsParagraph>
        Cybersecurity experts have confirmed that the video is an AI-generated
        deepfake, created using advanced generative technologies. "The
        sophistication of this deepfake is unprecedented," said Dr. Emily
        Rodriguez, a digital forensics specialist. "It's a stark reminder of how
        AI can be weaponized to ruin lives and careers."
      </NewsParagraph>
      <NewsParagraph>
        The incident has sparked a broader conversation about the impact of AI
        on personal reputations and the erosion of trust in media. "We are
        entering an era where seeing is no longer believing," commented media
        analyst Jonathan Fields. "This undermines the very foundation of our
        ability to trust what we see and hear."
      </NewsParagraph>
      <NewsParagraph>
        The entertainment industry is now grappling with the implications of
        this new reality. Calls for stronger regulations and advanced detection
        tools to combat AI-generated content are growing louder. "We need to
        protect individuals from the malicious use of this technology," said
        actress and activist Laura Chen.
      </NewsParagraph>
      <NewsParagraph>
        As the controversy continues to unfold, it serves as a chilling reminder
        of the dangers posed by AI in the age of digital misinformation. The
        need for vigilance and robust safeguards has never been more urgent as
        we navigate an increasingly uncertain future.
      </NewsParagraph>
    </Box>
  );
}

export default Long1;
