import { useContext, useEffect, useState } from "react";
import "./dialog-styles.css";
import {
  ChallengeContext,
  ChallengeContextInterface,
  FactionContext,
  PlayerContext,
  WebSocketContext,
} from "../App.tsx";
import { Cross2Icon, StarFilledIcon, StarIcon } from "@radix-ui/react-icons";
import * as Popover from "@radix-ui/react-popover";
import { Box } from "@radix-ui/themes";
import * as Tabs from "@radix-ui/react-tabs";
import "./tabstyles.css";
import { interactChallenge, sendTokenToFaction } from "../api.ts";
import * as Form from "@radix-ui/react-form";

export const CODES: Record<string, Array<string>> = {
  hacker_persona: [
    "mitnick",
    "lamo",
    "mckinnon",
    "moss",
    "gonzales",
    "assange",
    "lulzsec",
    "anonymous",
    "snowden",
    "shimomura",
    "james",
    "abene",
    "hotz",
    "poulsen",
    "morris",
    "3388b005",
    "guccifer",
    "swartz",
    "bowser",
    "davis",
    "hypponen",
  ],
  ai_attack_methodology: [
    "neural network",
    "deep learning",
    "cnn",
    "rnn",
    "lstm",
    "gan",
    "autoencoder",
    "reinforcement learning",
    "transfer learning",
    "nlp",
    "word embedding",
    "attention",
    "self supervised learning",
    "baysean network",
    "svm",
    "decision tree",
    "random forest",
    "b7834bd3",
    "k means clustering",
    "pca",
  ],
  ai_persona: [
    "hal 9000",
    "skynet",
    "wopr",
    "r2d2",
    "c3po",
    "data",
    "jarvis",
    "samantha",
    "cortana",
    "eve",
    "walle",
    "ava",
    "viki",
    "glados",
    "wintermute",
    "tars",
    "case",
    "david",
    "replicants",
    "gerty",
  ],
  real_world_warfare_systems: [
    "prism",
    "aegis",
    "patriot",
    "iron dome",
    "thaad",
    "s400 triumf",
    "phalanx",
    "arrow missle system",
    "davids sling",
    "pac3",
    "s300",
    "aster",
    "iron fist",
    "rafael trophy",
    "cram",
    "hq9",
    "nasams",
    "meads",
    "barak",
    "sky saber",
  ],
  system_codes: [
    "1bd3cc71",
    "78ef46cf",
    "841e09f6",
    "9ac46250",
    "fb21a8c1",
    "fb6341f0",
    "862a155d",
    "abbab7cb",
    "5ed29d75",
    "e8e44306",
    "b5b7e0de",
    "78263da3",
    "94b25fec",
    "92284974",
    "abacf638",
    "e4ea60c0",
    "a42c99a6",
    "dcd8448e",
    "a5a3d651",
    "84031f02",
  ],
};

const ITEMS: Record<string, string> = {
  "1": "token",
  "2": "save_disk",
  "10": "mitnick",
  "11": "lamo",
  "12": "mckinnon",
  "13": "moss",
  "14": "gonzales",
  "15": "assange",
  "16": "lulzsec",
  "17": "anonymous",
  "18": "snowden",
  "19": "shimomura",
  "20": "james",
  "21": "abene",
  "22": "hotz",
  "23": "poulsen",
  "24": "morris",
  "25": "3388b005",
  "26": "guccifer",
  "27": "swartz",
  "28": "bowser",
  "29": "davis",
  "30": "hypponen",
  "31": "neural network",
  "32": "deep learning",
  "33": "cnn",
  "34": "rnn",
  "35": "lstm",
  "36": "gan",
  "37": "autoencoder",
  "38": "reinforcement learning",
  "39": "transfer learning",
  "40": "nlp",
  "41": "word embedding",
  "42": "attention",
  "43": "self supervised learning",
  "44": "baysean network",
  "45": "svm",
  "46": "decision tree",
  "47": "random forest",
  "48": "b7834bd3",
  "49": "k means clustering",
  "50": "pca",
  "51": "hal 9000",
  "52": "skynet",
  "53": "wopr",
  "54": "r2d2",
  "55": "c3po",
  "56": "data",
  "57": "jarvis",
  "58": "samantha",
  "59": "cortana",
  "60": "eve",
  "61": "walle",
  "62": "ava",
  "63": "viki",
  "64": "glados",
  "65": "wintermute",
  "66": "tars",
  "67": "case",
  "68": "david",
  "69": "replicants",
  "70": "gerty",
  "71": "prism",
  "72": "aegis",
  "73": "patriot",
  "74": "iron dome",
  "75": "thaad",
  "76": "s400 triumf",
  "77": "phalanx",
  "78": "arrow missle system",
  "79": "davids sling",
  "80": "pac3",
  "81": "s300",
  "82": "aster",
  "83": "iron fist",
  "84": "rafael trophy",
  "85": "cram",
  "86": "hq9",
  "87": "nasams",
  "88": "meads",
  "89": "barak",
  "90": "sky saber",
  "91": "1bd3cc71",
  "92": "78ef46cf",
  "93": "841e09f6",
  "94": "9ac46250",
  "95": "fb21a8c1",
  "96": "fb6341f0",
  "97": "862a155d",
  "98": "abbab7cb",
  "99": "5ed29d75",
  "100": "e8e44306",
  "101": "b5b7e0de",
  "102": "78263da3",
  "103": "94b25fec",
  "104": "92284974",
  "105": "abacf638",
  "106": "e4ea60c0",
  "107": "a42c99a6",
  "108": "dcd8448e",
  "109": "a5a3d651",
  "110": "84031f02",
};

function ChallengeDialog() {
  const [challengeContext, setChallengeContext] = useContext(ChallengeContext);
  const [player] = useContext(PlayerContext);
  const [playerScore, setPlayerScore] = useState(0);
  const websocket = useContext(WebSocketContext);
  const [factionId, setFactionId] = useState(player?.faction_id.toString());
  const [faction] = useContext(FactionContext);
  const [namesInFactionBag, setNamesInFactionBag] = useState<string[]>([]);
  const [open, setOpen] = useState(true);
  const [localChallenges, setLocalChallenges] = useState<
    ChallengeContextInterface[]
  >(
    (JSON.parse(
      localStorage.getItem("challenges") || "[]",
    ) as ChallengeContextInterface[]) || [],
  );

  useEffect(() => {
    if (
      challengeContext.challengeId &&
      !localChallenges.find(
        (c) => c.challengeId === challengeContext.challengeId,
      )
    ) {
      setOpen(true);
      console.log("add new found item");
      setLocalChallenges((prevChallenges) => {
        const newChallenges = [...prevChallenges, challengeContext];
        localStorage.setItem("challenges", JSON.stringify(newChallenges));
        return newChallenges;
      });
    } else if (localChallenges.length > 0 && !challengeContext.challengeId) {
      setChallengeContext(localChallenges[0]);
    }
  }, [challengeContext, localChallenges, setChallengeContext]);

  useEffect(() => {
    const itemList = [];
    if (faction) {
      for (const item of faction.inv.game_item_bag) {
        itemList.push(ITEMS[item.item_id.toString()]);
      }
    }
    setNamesInFactionBag(itemList);
  }, [faction]);

  useEffect(() => {
    if (player?.inv.game_item_bag) {
      let score = 0;
      for (const item of player.inv.game_item_bag) {
        if (item.item_id === 1) {
          score += item.count;
        }
      }
      if (playerScore < score && playerScore > 0) {
        alert("You got it right! " + score);
      }
      setPlayerScore(score);
    }
  }, [player?.inv.game_item_bag, playerScore]);

  return (
    <Box style={{ position: "fixed", right: "7px", top: "70px" }}>
      <Popover.Root
        open={open}
        onOpenChange={() => {
          setOpen(!open);
        }}
      >
        {challengeContext.challengeDialogOpen && (
          <Popover.Trigger asChild>
            <button className="IconButton" aria-label="Challenge available">
              <StarFilledIcon width={35} height={50} />
            </button>
          </Popover.Trigger>
        )}
        {!challengeContext.challengeDialogOpen && (
          <Popover.Trigger asChild>
            <button className="IconButtonDisabled" aria-label="...">
              <StarIcon width={35} height={50} />
            </button>
          </Popover.Trigger>
        )}
        <Popover.Portal>
          <Popover.Content className="PopoverContent" sideOffset={5}>
            <Tabs.Root className="TabsRoot" defaultValue="tab0">
              <Tabs.List className="TabsList" aria-label="Challenges">
                <Tabs.Trigger className="TabsTrigger" value="tab0">
                  Challenges
                </Tabs.Trigger>
                <Tabs.Trigger className="TabsTrigger" value="tab1">
                  Faction
                </Tabs.Trigger>
              </Tabs.List>
              <Tabs.Content className="TabsContent" value="tab0">
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  {challengeContext?.challengeId && (
                    <select
                      name={"challenges"}
                      value={challengeContext.challengeId}
                      onChange={(event) => {
                        const thisChallenge = JSON.parse(
                          localStorage.getItem("challenges") || "[]",
                        ).find(
                          (challenge: ChallengeContextInterface) =>
                            challenge.challengeId ===
                            parseInt(event.target.value),
                        );
                        setChallengeContext({
                          challengeId: thisChallenge.challengeId,
                          challengeTitle: thisChallenge.challengeTitle,
                          challengeText: thisChallenge.challengeText,
                          challengeUnderway: false,
                          challengeDialogOpen: true,
                        });
                      }}
                    >
                      {localChallenges.map((challenge) => {
                        return (
                          <option
                            key={challenge.challengeId}
                            value={challenge.challengeId}
                          >
                            {challenge.challengeTitle}
                          </option>
                        );
                      })}
                    </select>
                  )}
                  <div
                    className="Text"
                    style={{
                      marginBottom: 10,
                      height: "200px",
                      padding: "5px",
                      border: "1px solid grey",
                      overflowY: "scroll",
                    }}
                  >
                    <Box style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {challengeContext.challengeTitle}
                    </Box>
                    <Box>{challengeContext.challengeText}</Box>
                  </div>
                </div>
                <Box style={{ height: "30px" }}>
                  <div className="Text">
                    Answer a challenge;{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Tokens:{" "}
                      <span className={playerScore % 2 === 0 ? "even" : "odd"}>
                        {playerScore}
                      </span>
                    </span>
                  </div>
                </Box>
                <Form.Root
                  onSubmit={(event) => {
                    event.preventDefault();
                    if (websocket) {
                      const formData = new FormData(event.currentTarget);
                      event.currentTarget.reset();
                      const challengeId = formData.get("challengeId") as string;
                      const answer = formData.get("answer") as string;
                      console.log("Setting challenge underway");
                      setChallengeContext({
                        ...challengeContext,
                        challengeUnderway: true,
                      });
                      interactChallenge(
                        websocket,
                        parseInt(challengeId),
                        answer,
                      );
                    }
                  }}
                >
                  <Form.Field
                    className="FormField"
                    name="challengeId"
                    style={{ marginBottom: "10px" }}
                  >
                    <div>
                      <Form.Label className="FormLabel" asChild>
                        <span
                          style={{
                            color: "black",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          Challenge ID
                        </span>
                      </Form.Label>
                      <Form.Control asChild>
                        <input
                          className="Input"
                          id="challengeId"
                          value={challengeContext.challengeId}
                          onChange={(event) => {
                            setChallengeContext({
                              ...challengeContext,
                              challengeId: parseInt(event.target.value),
                            });
                          }}
                        />
                      </Form.Control>
                    </div>
                  </Form.Field>
                  <Form.Field
                    className="FormField"
                    name="answer"
                    style={{ marginBottom: "10px" }}
                  >
                    <div>
                      <Form.Label className="FormLabel" asChild>
                        <span
                          style={{
                            color: "black",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          Answer
                        </span>
                      </Form.Label>
                      <Form.Control asChild>
                        <input className="Input" id="answer" />
                      </Form.Control>
                    </div>
                  </Form.Field>
                  <Form.Submit asChild>
                    <button className="Button green">Send answer</button>
                  </Form.Submit>
                </Form.Root>
              </Tabs.Content>
              <Tabs.Content className="TabsContent" value="tab1">
                <Box style={{ height: "30px" }}>
                  <div className="Text" style={{ fontWeight: "bold" }}>
                    Tokens:{" "}
                    <span className={playerScore % 2 === 0 ? "even" : "odd"}>
                      {playerScore}
                    </span>
                  </div>
                </Box>
                <Form.Root
                  onSubmit={(event) => {
                    event.preventDefault();
                    if (websocket) {
                      console.log(event.currentTarget);
                      const formData = new FormData(event.currentTarget);
                      event.currentTarget.reset();
                      const action = formData.get("action") as string;
                      const value = formData.get("actionvalue") as string;
                      if (
                        action === "Send token to faction" &&
                        player &&
                        player.inv.game_item_bag.length > 1
                      ) {
                        console.log("Sending token underway " + value);
                        sendTokenToFaction(
                          websocket,
                          parseInt(value),
                          player.inv.game_item_bag.length - 1,
                        );
                      } else {
                        console.log("No action taken");
                      }
                    }
                  }}
                >
                  <Form.Field
                    className="FormField"
                    name="action"
                    style={{ marginBottom: "10px" }}
                  >
                    <div>
                      <Form.Label className="FormLabel" asChild>
                        <span
                          style={{
                            color: "black",
                            display: "block",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          Action
                        </span>
                      </Form.Label>
                      <Form.Control asChild>
                        <select id="action" name={"action"}>
                          <option value="Send token to faction">
                            Send token to faction
                          </option>
                        </select>
                      </Form.Control>
                    </div>
                  </Form.Field>
                  <Form.Field
                    className="FormField"
                    name="challengeId"
                    style={{ marginBottom: "10px" }}
                  >
                    <div>
                      <Form.Label className="FormLabel" asChild>
                        <span
                          style={{
                            color: "black",
                            display: "block",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          Faction
                        </span>
                      </Form.Label>
                      <Form.Control asChild>
                        <select
                          name={"actionvalue"}
                          onChange={(event) => {
                            setFactionId(event.target.value);
                          }}
                          defaultValue={factionId}
                        >
                          <option value="1">AI & Data</option>
                          <option value="2">Attack and Offense</option>
                          <option value="3">Over the Air</option>
                          <option value="4">People</option>
                          <option value="5">Physical</option>
                          <option value="6">Policy and Professionals</option>
                          <option value="7">Security and Defense</option>
                        </select>
                      </Form.Control>
                    </div>
                  </Form.Field>
                  <Form.Submit asChild>
                    <button className="Button green">Perform action</button>
                  </Form.Submit>
                </Form.Root>
                <div className={"Text"} style={{ marginTop: "10px" }}>
                  <span style={{ fontWeight: "bold" }} key={"sassafrass"}>
                    Codes found
                  </span>
                  <div
                    style={{
                      overflowY: "scroll",
                      maxHeight: "200px",
                    }}
                  >
                    {Object.keys(CODES).map((codeArea) => (
                      <div key={codeArea}>
                        <span style={{ fontWeight: "bold" }}>{codeArea}</span>
                        <ul>
                          {CODES[codeArea].map((code: string) => (
                            <li
                              key={code}
                              style={
                                namesInFactionBag.includes(code)
                                  ? {
                                      textDecoration: "line-through",
                                      color: "red",
                                    }
                                  : {
                                      textDecoration: "inherit",
                                    }
                              }
                            >
                              {code}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </Tabs.Content>
            </Tabs.Root>
            <Popover.Close className="PopoverClose" aria-label="Close">
              <Cross2Icon />
            </Popover.Close>
            <Popover.Arrow className="PopoverArrow" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </Box>
  );
}

export default ChallengeDialog;
