export const FACTIONS: Record<number, string> = {
  1: "AI and Data",
  2: "Attack and Offense",
  3: "Over the Air",
  4: "People",
  5: "Physical",
  6: "Policy and Professionals",
  7: "Security and Defense",
};

export interface BagItem {
  item_id: number;
  count: number;
}

export interface Inventory {
  game_item_bag_iid: string;
  game_item_bag: BagItem[];
  buff_item_bag_iid: string;
  buff_item_bag: BagItem[];
}

export interface PlayerStruct {
  player_id: string;
  account_id: string;
  name: string;
  faction_id: number;
  tagline: string;
  coordx: number;
  coordy: number;
  charisma: number;
  intelligence: number;
  dexterity: number;
  strength: number;
  wisdom: number;
  constitution: number;
  reputation: number;
  private_signing_key: string;
  public_signing_key: string;
  inventory_id: string;
  login_hash: string;
  inv: Inventory;
}

export interface CreatePlayerStruct {
  name: string;
  fid: number;
  tagline: string;
}

export interface CreatePlayerMessage {
  CreatePlayer: CreatePlayerStruct;
}

export interface AccountStruct {
  username: string;
  password: string;
}

export interface CreateAccountMessage {
  CreateAccount: AccountStruct;
}

export interface LoginMessage {
  Login: AccountStruct;
}

export interface FactionStruct {
  faction_id: string;
  faction_name: string;
  inventory_id: string;
  inv: Inventory;
}

export const sendMessage = (
  websocket: WebSocket,
  message: Record<string, any>,
) => {
  if (message === null) {
    console.log("Can't send a null message");
    return;
  }
  // Send the msg object as a JSON-formatted string.
  if (websocket !== null) {
    websocket.send(JSON.stringify(message));
  } else {
    console.log("Tried to send a message but the websocket wasn't defined");
  }
};

export const createAccount = (
  websocket: WebSocket,
  username: string,
  password: string,
) => {
  const playerCreateMessage: CreateAccountMessage = {
    CreateAccount: {
      username: username,
      password: password,
    },
  };
  sendMessage(websocket, playerCreateMessage);
};

export const createPlayer = (
  websocket: WebSocket,
  name: string,
  faction_id: number,
  tagline: string,
) => {
  const playerCreateMessage: CreatePlayerMessage = {
    CreatePlayer: {
      name: name,
      fid: faction_id,
      tagline: tagline,
    },
  };
  sendMessage(websocket, playerCreateMessage);
};

export const loginAccount = (
  websocket: WebSocket,
  username: string,
  password: string,
) => {
  const loginMessage: LoginMessage = {
    Login: {
      username: username,
      password: password,
    },
  };
  sendMessage(websocket, loginMessage);
};

export interface FactionInventoryRequest {
  fid: number;
}

export interface RequestFactionInventoryMessage {
  RequestFactionInv: FactionInventoryRequest;
}

export const getFactionInventory = (
  websocket: WebSocket,
  faction_id: number,
) => {
  const message: RequestFactionInventoryMessage = {
    RequestFactionInv: {
      fid: faction_id,
    },
  };
  sendMessage(websocket, message);
};

interface GetChallengeTextMessage {
  GetChallengeText: {
    challenge_id: number;
  };
}

export const getChallengeText = (websocket: WebSocket, challengeId: number) => {
  const message: GetChallengeTextMessage = {
    GetChallengeText: {
      challenge_id: challengeId,
    },
  };
  sendMessage(websocket, message);
};

interface InteractChallengeMessage {
  InteractChallenge: {
    challenge_id: number;
    answer: string;
  };
}

interface CheckWinMessage {
  CheckWin: {
    answer1: string;
    answer2: string;
    answer3: string;
    answer4: string;
    answer5: string;
  };
}

export const interactChallenge = (
  websocket: WebSocket,
  challengeId: number,
  answer: string,
) => {
  if (challengeId === 250) {
    const answers = answer.split(" ");
    const answer1 = answers[0];
    const answer2 = answers[1];
    const answer3 = answers[2];
    const answer4 = answers[3];
    const answer5 = answers[4];
    const message: CheckWinMessage = {
      CheckWin: {
        answer1: answer1,
        answer2: answer2,
        answer3: answer3,
        answer4: answer4,
        answer5: answer5,
      },
    };
    sendMessage(websocket, message);
  } else {
    const message: InteractChallengeMessage = {
      InteractChallenge: {
        challenge_id: challengeId,
        answer: answer,
      },
    };
    sendMessage(websocket, message);
  }
};

export const getPlayer = (websocket: WebSocket, player_id: string) => {
  sendMessage(websocket, { RequestPlayer: { player_id: player_id } });
};

interface MoveItemToFactionMessage {
  MoveItemToFaction: {
    fid: number;
    slot_id: number;
  };
}

export const sendTokenToFaction = (
  websocket: WebSocket,
  faction_id: number,
  slot: number,
) => {
  const message: MoveItemToFactionMessage = {
    MoveItemToFaction: {
      fid: faction_id,
      slot_id: slot,
    },
  };
  sendMessage(websocket, message);
};

interface LoginWithHashMessage {
  LoginWithHash: {
    hash: string;
  };
}

export const loginWithHash = (websocket: WebSocket, loginHash: string) => {
  const message: LoginWithHashMessage = {
    LoginWithHash: {
      hash: loginHash,
    },
  };
  sendMessage(websocket, message);
};

export const triggerLeaderboardMessages = (websocket: WebSocket) => {
  getFactionInventory(websocket, 1);
  getFactionInventory(websocket, 2);
  getFactionInventory(websocket, 3);
  getFactionInventory(websocket, 4);
  getFactionInventory(websocket, 5);
  getFactionInventory(websocket, 6);
  getFactionInventory(websocket, 7);
};

export const getPlayerScoreboard = (websocket: WebSocket) => {
  sendMessage(websocket, { GetScoreBoard: {} });
};
