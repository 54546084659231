import { Actor } from "./actor";
import { Player } from "./player";
import { EVENTS_NAME } from "../consts.ts";

export class EnemyActor extends Actor {
  AGRESSOR_RADIUS: number;
  ATTACK_SPEED: number;
  target: Player;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    target: Player,
    frame?: string | number,
  ) {
    super(scene, x, y, texture, frame);
    this.target = target;
    // ADD TO SCENE
    scene.add.existing(this);
    scene.physics.add.existing(this);
    // PHYSICS MODEL
    this.getBody().setSize(16, 16);
    this.getBody().setOffset(0, 0);

    // EVENTS
    this.scene.game.events.on(EVENTS_NAME.attack, this.attackHandler, this);
    this.on("destroy", () => {
      this.scene.game.events.removeListener(
        EVENTS_NAME.attack,
        this.attackHandler,
      );
    });
  }

  preUpdate(): void {
    if (
      Phaser.Math.Distance.BetweenPoints(
        { x: this.x, y: this.y },
        { x: this.target.x, y: this.target.y },
      ) < this.AGRESSOR_RADIUS
    ) {
      const distance = Phaser.Math.Distance.BetweenPoints(
        { x: this.x, y: this.y },
        { x: this.target.x, y: this.target.y },
      );
      const speedFactor = this.ATTACK_SPEED / distance;
      this.getBody().setVelocityX((this.target.x - this.x) * speedFactor);
      this.getBody().setVelocityY((this.target.y - this.y) * speedFactor);
    } else {
      this.getBody().setVelocity(0);
    }
  }

  public attackHandler = () => {
    if (
      Phaser.Math.Distance.BetweenPoints(
        { x: this.x, y: this.y },
        { x: this.target.x, y: this.target.y },
      ) <
        this.target.width / 1.25 &&
      ((this.target.scaleX === 1 && this.x > this.target.x) ||
        (this.target.scaleX === -1 && this.x < this.target.x) ||
        (this.target.rotation > 0 && this.y > this.target.y) ||
        (this.target.rotation < 0 && this.y < this.target.y))
    ) {
      this.animateDamage();
      this.hp = this.hp - 100;
      if (this.hp <= 0) {
        this.disableBody(true, false);
        this.scene.time.delayedCall(300, () => {
          this.destroy();
        });
      }
    }
  };

  public setTarget(target: Player): void {
    this.target = target;
  }
}

export class Enemy extends EnemyActor {
  AGRESSOR_RADIUS = 500;
  ATTACK_SPEED = 50;
}

export class Enemy2 extends EnemyActor {
  AGRESSOR_RADIUS = 200;
  ATTACK_SPEED = 150;
}

export class Enemy3 extends EnemyActor {
  AGRESSOR_RADIUS = 1500;
  ATTACK_SPEED = 18;
  hp = 500;
}
