import { Actor } from "./actor";
import { Text } from "./text";
import { EVENTS_NAME, GameStatus } from "../consts.ts";
import { Input } from "phaser";
import { DEFAULT_PLAYER_START_LOCATION } from "../scenes/levels/Level1.ts";

export class Player extends Actor {
  private keyW: Phaser.Input.Keyboard.Key;
  private keyA: Phaser.Input.Keyboard.Key;
  private keyS: Phaser.Input.Keyboard.Key;
  private keyD: Phaser.Input.Keyboard.Key;
  private hpValue: Text;
  private keySpace: Input.Keyboard.Key;
  VELOCITY = 110;
  hp = 200;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, "king");
    // KEYS
    if (this && this.scene && this.scene.input && this.scene.input.keyboard) {
      this.keyW = this.scene.input.keyboard.addKey("W", false);
      this.keyA = this.scene.input.keyboard.addKey("A", false);
      this.keyS = this.scene.input.keyboard.addKey("S", false);
      this.keyD = this.scene.input.keyboard.addKey("D", false);
    }
    if (this.keyW) {
      this.keyW.on("up", (_event: KeyboardEvent) => {
        this.setVelocity(0);
      });
    }
    if (this.keyA) {
      this.keyA.on("up", (_event: KeyboardEvent) => {
        this.setVelocity(0);
      });
    }
    if (this.keyS) {
      this.keyS.on("up", (_event: KeyboardEvent) => {
        this.setVelocity(0);
      });
    }
    if (this.keyD) {
      this.keyD.on("up", (_event: KeyboardEvent) => {
        this.setVelocity(0);
      });
    }
    // PHYSICS
    this.getBody().setSize(30, 30);
    this.getBody().setOffset(8, 0);
    // HP
    this.hpValue = new Text(
      this.scene,
      this.x,
      this.y - this.height,
      this.hp.toString(),
    )
      .setFontSize(12)
      .setOrigin(0.8, 0.5);
    // Defense
    if (this.scene.input.keyboard) {
      this.keySpace = this.scene.input.keyboard.addKey(32, false);
      this.keySpace.on("down", (_event: KeyboardEvent) => {
        this.anims.play("attack", true);
        this.scene.game.events.emit(EVENTS_NAME.attack);
      });
    }
    // Animate
    this.initAnimations();
    // Handle game restart
    this.on("destroy", () => {
      this.keySpace.removeAllListeners();
    });
    this.scene.game.events.on(
      EVENTS_NAME.chestLoot,
      () => {
        this.hp += 10;
      },
      this,
    );
  }

  update(): void {
    // this.getBody().setVelocity(0);
    this.hpValue.setPosition(this.x, this.y - this.height * 0.4);
    this.hpValue.setOrigin(0.8, 0.5);
    this.hpValue.setText(this.hp.toString());
    if (this.body) {
      // are two keys down?
      let velFactor = 1;
      if (
        (this.keyW?.isDown && this.keyA?.isDown) ||
        (this.keyW?.isDown && this.keyD?.isDown) ||
        (this.keyS?.isDown && this.keyA?.isDown) ||
        (this.keyS?.isDown && this.keyD?.isDown)
      ) {
        velFactor = Math.sqrt(2) / 2;
      }
      if (this.keyW?.isDown) {
        this.body.velocity.y = -this.VELOCITY * velFactor;
        this.checkFlip();
      }
      if (this.keyA?.isDown) {
        this.body.velocity.x = -this.VELOCITY * velFactor;
        this.checkFlip();
        this.getBody().setOffset(48, 15);
      }
      if (this.keyS?.isDown) {
        this.body.velocity.y = this.VELOCITY * velFactor;
        this.checkFlip();
      }
      if (this.keyD?.isDown) {
        this.body.velocity.x = this.VELOCITY * velFactor;
        this.checkFlip();
        this.getBody().setOffset(15, 15);
      }
      if (this.body.velocity.x !== 0 || this.body.velocity.y !== 0) {
        !this.anims.isPlaying && this.anims.play("run", true);
      }
    }
  }

  public animateDamage(value?: number): void {
    super.animateDamage(value);
    this.hpValue.setText(this.hp.toString());
    if (this.hp <= 0) {
      this.x = DEFAULT_PLAYER_START_LOCATION[0];
      this.y = DEFAULT_PLAYER_START_LOCATION[1];
      this.scene.game.events.emit(EVENTS_NAME.gameEnd, GameStatus.LOSE);
    }
  }

  private initAnimations(): void {
    this.scene.anims.create({
      key: "run",
      frames: this.scene.anims.generateFrameNames("a-king", {
        prefix: "run-",
        end: 7,
      }),
      frameRate: 8,
    });
    this.scene.anims.create({
      key: "attack",
      frames: this.scene.anims.generateFrameNames("a-king", {
        prefix: "attack-",
        end: 2,
      }),
      frameRate: 8,
    });
  }
}
