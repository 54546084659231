import { Box } from "@radix-ui/themes";
import NewsParagraph from "../components/NewsParagraph.tsx";

function Long2() {
  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: "10px",
      }}
    >
      <NewsParagraph>
        AI-Generated Hoax Leads to Mass Panic at Music Festival
      </NewsParagraph>
      <NewsParagraph>August 8, 2024</NewsParagraph>
      <NewsParagraph>
        LONDON, UK (AP) – Chaos erupted at the Glastonbury Music Festival
        yesterday after an AI-generated hoax about a terrorist attack spread
        like wildfire on social media, causing mass panic among attendees. The
        false report claimed that a bomb had been planted at the venue, leading
        to a frantic evacuation and numerous injuries.
      </NewsParagraph>
      <NewsParagraph>
        Festival-goers described scenes of utter chaos as they rushed to exit
        the crowded event. "It was terrifying. People were screaming and
        trampling over each other," said attendee Sarah Mitchell. "I saw several
        people getting hurt in the stampede."
      </NewsParagraph>
      <NewsParagraph>
        Authorities quickly investigated the claims and confirmed that there was
        no credible threat. "This was a malicious hoax, generated by AI and
        disseminated to cause maximum disruption," said Metropolitan Police
        Commissioner Mark Edwards. "We are working to identify the perpetrators
        and hold them accountable."
      </NewsParagraph>
      <NewsParagraph>
        The incident has raised serious concerns about the role of AI in
        spreading misinformation and inciting public fear. "The ability of AI to
        generate and amplify false information at such a scale is deeply
        troubling," said Dr. Alex Patel, an expert in digital security. "This
        incident highlights the urgent need for better control and monitoring of
        AI technologies."
      </NewsParagraph>
      <NewsParagraph>
        Festival organizers have vowed to enhance security measures and improve
        communication channels to prevent future incidents. "We are devastated
        by what happened and are taking steps to ensure the safety of our
        attendees," said organizer Emily Jameson.
      </NewsParagraph>
      <NewsParagraph>
        As the festival community grapples with the aftermath, the broader
        public is left to ponder the implications of living in an era where AI
        can so easily manipulate reality and sow chaos. The need for robust
        defenses against digital deception has never been more apparent.
      </NewsParagraph>
    </Box>
  );
}

export default Long2;
