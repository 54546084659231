import { Box, Callout } from "@radix-ui/themes";
import { MoveIcon } from "@radix-ui/react-icons";
import { useContext } from "react";
import { PlayerContext } from "../App.tsx";
import { FACTIONS } from "../api.ts";

const Faction = () => {
  const [player] = useContext(PlayerContext);
  return (
    <Box
      style={{
        display: "inline-block",
        position: "fixed",
        right: "215px",
        top: "5px",
        opacity: "0.70",
      }}
    >
      {player && player.faction_id && (
        <Callout.Root
          size={"1"}
          variant={"outline"}
          color={"red"}
          style={{ backgroundColor: "black", padding: "9px" }}
        >
          <Callout.Icon>
            <MoveIcon />
          </Callout.Icon>
          <Callout.Text>Faction: {FACTIONS[player?.faction_id]}</Callout.Text>
        </Callout.Root>
      )}
    </Box>
  );
};

export { Faction };
