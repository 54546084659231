import { Scene } from "phaser";
import { EVENTS_NAME, GameStatus } from "../../consts.ts";
import { Text } from "../../classes/text";

export class Level1UIScene extends Scene {
  gameEndHandler: (status: GameStatus) => void;
  private gameEndPhrase!: Text;

  constructor() {
    super("Level1UIScene");
    // Game conclusion
    this.gameEndHandler = (status) => {
      this.cameras.main.setBackgroundColor("rgba(0,0,0,0.6)");
      this.game.scene.pause("level-1-scene");
      this.gameEndPhrase = new Text(
        this,
        this.game.scale.width / 2,
        this.game.scale.height * 0.4,
        status === GameStatus.LOSE
          ? `WASTED!\nCLICK TO RESTART`
          : `YOU ARE ROCK!\nCLICK TO RESTART`,
      )
        .setAlign("center")
        .setColor(status === GameStatus.LOSE ? "#ff0000" : "#ffffff");
      this.gameEndPhrase.setPosition(
        this.game.scale.width / 2 - this.gameEndPhrase.width / 2,
        this.game.scale.height * 0.4,
      );
      // restart on click
      this.input.on("pointerdown", () => {
        this.game.events.off(EVENTS_NAME.gameEnd, this.gameEndHandler);
        this.scene.get("Level1").scene.restart();
        this.scene.restart();
      });
    };
    // Win condition
    // this.chestLootHandler = () => {
    //   this.hpViewer.changeValue(ScoreOperations.INCREASE, 10);
    //   if (this.hpViewer.getValue() === winScore) {
    //     this.game.events.emit(EVENTS_NAME.gameEnd, "win");
    //   }
    // };
  }

  create(): void {
    this.initListeners();
  }

  private initListeners(): void {
    this.game.events.on(EVENTS_NAME.gameEnd, this.gameEndHandler, this);
  }
}
