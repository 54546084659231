import { Box } from "@radix-ui/themes";
import NewsParagraph from "../components/NewsParagraph.tsx";

function Long3() {
  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: "10px",
      }}
    >
      <NewsParagraph>
        AI-Generated Fashion Trends Cause Market Turmoil
      </NewsParagraph>
      <NewsParagraph>August 4, 2024</NewsParagraph>
      <NewsParagraph>
        PARIS, FRANCE (AP) – The global fashion industry is reeling after
        AI-generated fashion trends caused market turmoil, leading to
        significant financial losses for several high-profile brands. The
        incident began when a series of hyper-realistic images and videos
        showcasing new "trends" were disseminated across social media, quickly
        gaining traction and influencing consumer behavior.
      </NewsParagraph>
      <NewsParagraph>
        The AI-generated content, which featured outlandish and impractical
        designs, was initially believed to be the work of a cutting-edge
        designer. "We were all fooled," admitted fashion critic Miranda Blake.
        "The designs were so convincing that even seasoned industry
        professionals were duped." The AI-generated content, which featured
        outlandish and impractical designs, was initially believed to be the
        work of a cutting-edge designer. "We were all fooled," admitted fashion
        critic Miranda Blake. "The designs were so convincing that even seasoned
        industry professionals were duped."
      </NewsParagraph>
      <NewsParagraph>
        Major fashion houses, including Chanel and Dior, reported substantial
        drops in sales as consumers shifted their spending towards the fake
        trends. "This has had a devastating impact on our quarterly
        performance," said Chanel CEO Jean-Luc Moreau. "We are now investigating
        how this happened and what measures can be taken to prevent it in the
        future."
      </NewsParagraph>
      <NewsParagraph>
        Digital forensics experts have traced the source of the AI-generated
        trends to a sophisticated network of bots and algorithms. "This was a
        coordinated attack on the fashion industry," said cybersecurity analyst
        Dr. Laura Simmons. "The intent appears to be to disrupt and destabilize
        the market."
      </NewsParagraph>
      <NewsParagraph>
        The incident has sparked a debate about the vulnerability of the fashion
        industry to digital manipulation. "We need to develop better tools to
        detect and counteract AI-generated content," said fashion technology
        consultant Rachel Kim. "The integrity of our industry is at stake."
      </NewsParagraph>
      <NewsParagraph>
        As brands scramble to recover and restore consumer trust, the broader
        implications of AI's role in shaping public perception and behavior are
        becoming increasingly clear. The fashion world, like many other sectors,
        must now confront the reality of an AI-driven future where authenticity
        and trust are constantly in question.
      </NewsParagraph>
    </Box>
  );
}

export default Long3;
