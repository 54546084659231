import { Scene } from "phaser";
import { EventBus } from "../../EventBus.ts";
import { EVENTS_NAME } from "../../consts.ts";
import { mobileAndTabletCheck } from "../../helpers/gameobject-to-object-point.ts";

export class SoundTrackScene extends Scene {
  nextSong: string = "0";
  currentSong: Phaser.Sound.BaseSound;
  playing: boolean = true;

  constructor() {
    super("SoundTrackScene");
  }

  update() {
    if (!mobileAndTabletCheck()) {
      if (!this.currentSong.isPlaying) {
        this.nextSong = ((parseInt(this.nextSong) + 1) % 13).toString();
        console.log(`starting ${this.nextSong}`);
        this.currentSong = this.sound.add(this.nextSong, { volume: 0.5 });
        this.currentSong.play();
      }
    }
  }

  create(): void {
    console.log("Starting sound track");
    this.initListeners();
    if (!mobileAndTabletCheck()) {
      // turn on the music:
      this.currentSong = this.sound.add(this.nextSong, { volume: 0.5 });
      this.game.sound.volume = 0.25;
      this.currentSong.play();
      EventBus.on(EVENTS_NAME.music, () => {
        if (this.playing) {
          this.playing = false;
          this.game.sound.mute = true;
        } else {
          this.playing = true;
          this.game.sound.mute = false;
        }
      });
    }
  }

  private initListeners(): void {}
}
