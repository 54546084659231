import { Box } from "@radix-ui/themes";
import NewsParagraph from "../components/NewsParagraph.tsx";

const Nsa = () => {
  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: "20px",
      }}
    >
      <NewsParagraph>
        NSA Uncovers Advanced AI-Generated Fake News in Major International
        Incident
      </NewsParagraph>
      <NewsParagraph>July 29, 2024</NewsParagraph>
      <NewsParagraph>
        WASHINGTON, D.C. (AP) – In a startling development, a specialized team
        within the National Security Agency (NSA) known as "Joshua" has
        uncovered several instances of falsified news items created using
        advanced language models and generative AI technologies. Among the most
        concerning discoveries is the complete fabrication of a politically
        motivated assassination attempt in Serbia.
      </NewsParagraph>
      <NewsParagraph>
        The NSA's Joshua team has identified numerous falsified reports, videos,
        and imagery that were so convincingly fabricated that they were
        initially accepted as authentic by reputable news agencies. The motives
        and identities behind these sophisticated forgeries remain unknown.
      </NewsParagraph>
      <NewsParagraph>
        "The level of sophistication in these AI-generated fakes is
        unprecedented," said an NSA spokesperson. "We are particularly concerned
        about the Serbian incident, which has far-reaching implications for
        international relations and domestic perceptions."
      </NewsParagraph>
      <NewsParagraph>
        The fabricated event in Serbia, which sparked significant political
        debate and social unrest, was meticulously crafted to deceive both the
        public and experts. The false reports included doctored videos and
        images that were widely circulated on social media and even referenced
        by major news outlets.
      </NewsParagraph>
      <NewsParagraph>
        Stephen Falken, a prominent leader in artificial intelligence at MIT,
        was interviewed about the Joshua team's findings. Falken expressed deep
        concern over the implications of these discoveries.
      </NewsParagraph>
      <NewsParagraph>
        "These findings underscore the urgent need for increased scrutiny of
        news articles and media content," Falken stated. "The ability of
        generative AI to create highly realistic, yet entirely false,
        information poses a significant threat to our understanding of truth and
        reality. We must accelerate the development of technologies capable of
        detecting AI-generated content to safeguard the integrity of
        information."
      </NewsParagraph>
      <NewsParagraph>
        The discovery by the Joshua team has raised alarms about the potential
        for other, currently undiscovered, AI-generated fakes to be attributed
        to reliable reporting agencies, such as The New York Times. This
        revelation suggests a broader and more insidious problem that could
        undermine public trust in media.
      </NewsParagraph>
      <NewsParagraph>
        "The fact that these fakes have infiltrated mainstream media without
        detection is deeply troubling," the NSA spokesperson added. "We are
        working closely with international partners and media organizations to
        address this issue and enhance our collective ability to identify and
        prevent the dissemination of false information."
      </NewsParagraph>
      <NewsParagraph>
        As the investigation continues, the NSA has emphasized the importance of
        public awareness and media literacy. They urge the public to critically
        evaluate the sources of information and remain vigilant against
        potential misinformation.
      </NewsParagraph>
      <NewsParagraph>
        The Joshua team's findings have sparked a renewed debate on the ethical
        use of AI and the responsibilities of tech companies in preventing the
        misuse of their technologies. While the full extent of the damage caused
        by these AI-generated fakes is still being assessed, one thing is clear:
        the battle against misinformation has entered a new and more challenging
        phase.
      </NewsParagraph>
    </Box>
  );
};

export default Nsa;
