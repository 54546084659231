import React, { useContext } from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import classNames from "classnames";
import { CaretDownIcon } from "@radix-ui/react-icons";
import "./navbar-styles.css";
import ObfuscatedText, { obfuscate } from "./ObfuscatedText.tsx";
import { ObfuscationContext, PlayerContext } from "../App.tsx";
import { Link } from "react-router-dom";

interface ListItemProps {
  title: string;
  children: React.ReactNode;
  to: string;
}

const ListItem = ({ title, children, to, ...props }: ListItemProps) => {
  return (
    <li>
      <NavigationMenu.Link asChild>
        <Link className={classNames("ListItemLink")} {...props} to={to}>
          <div className="ListItemHeading">{title}</div>
          <p className="ListItemText">
            <ObfuscatedText>{children as string}</ObfuscatedText>
          </p>
        </Link>
      </NavigationMenu.Link>
    </li>
  );
};

const Navbar = () => {
  const [obfuscated] = useContext(ObfuscationContext);
  const [player] = useContext(PlayerContext);

  return (
    <NavigationMenu.Root className="NavigationMenuRoot">
      <NavigationMenu.List className="NavigationMenuList">
        <NavigationMenu.Item>
          <Link className="NavigationMenuLink" to="/">
            <ObfuscatedText>Home</ObfuscatedText>
          </Link>
        </NavigationMenu.Item>
        <NavigationMenu.Item>
          <NavigationMenu.Trigger className="NavigationMenuTrigger">
            <ObfuscatedText>Game and Story</ObfuscatedText>{" "}
            <CaretDownIcon className="CaretDown" aria-hidden />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="NavigationMenuContent">
            <ul className="List one">
              <ListItem
                title={obfuscated ? obfuscate("Introduction") : "Introduction"}
                to="/introduction"
              >
                Set and setting: Defcon in the mix.
              </ListItem>
              <ListItem
                title={obfuscated ? obfuscate("Context") : "Context"}
                to="/context"
              >
                Deepfakes, AI and truth.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate("AP news item, July 29, 2024")
                    : "AP news item, July 29, 2024"
                }
                to="/ap"
              >
                Serbian prime minister, Viloš Mučević, believed to have been
                attacked.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate("NSA casts doubt on leaked recordings")
                    : "NSA casts doubt on leaked recordings"
                }
                to="/nsa"
              >
                Team "Joshua" fingerprints tampering in Serbian incident.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate("The world stage, and a new player")
                    : "The world stage, and a new player"
                }
                to="/world"
              >
                Op-ed from tech luminary Eliza Warren.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate("MIT: Evidence of LLM tampering")
                    : "MIT: Evidence of LLM tampering"
                }
                to="/mit"
              >
                Rising percentage of news footage edited without publisher's
                knowledge.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate("Fingerpointing at NATO summit")
                    : "Fingerpointing at NATO summit"
                }
                to="/fingerpointing"
              >
                Russia, Argentina and South Korea offer bold claims though
                evidence is scarce.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate(
                        "Mysterious Cyber Attacks Cripple Global Banking Systems",
                      )
                    : "Mysterious Cyber Attacks Cripple Global Banking Systems"
                }
                to="/flash1"
              >
                Fed to issue press release imminently.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate(
                        "Fabricated Climate Crisis Report Sparks International Uproar",
                      )
                    : "Fabricated Climate Crisis Report Sparks International Uproar"
                }
                to="/flash2"
              >
                Scientists call for increased scrutiny before reports are
                publicized, point to academic publishing standards as a guide.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate(
                        "Deepfake Video of Presidential Candidate Causes Political Turmoil",
                      )
                    : "Deepfake Video of Presidential Candidate Causes Political Turmoil"
                }
                to="/flash3"
              >
                Politicians blame big tech; big tech blames the politicians;
                media conglomerates caught in the crossfire.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate(
                        "AI-Generated False Flag Operation Escalates Tensions in the Middle East",
                      )
                    : "AI-Generated False Flag Operation Escalates Tensions in the Middle East"
                }
                to="/flash4"
              >
                Tensions mount in a region already on the brink of war.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate(
                        "AI-Driven Stock Market Manipulation Discovered",
                      )
                    : "AI-Driven Stock Market Manipulation Discovered"
                }
                to="/flash5"
              >
                Banks across the world react to new vector of attack.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate(
                        "AI Impersonates World Leaders in Coordinated Disinformation Campaign",
                      )
                    : "AI Impersonates World Leaders in Coordinated Disinformation Campaign"
                }
                to="/flash6"
              >
                Attack leaves NATO picking up the pieces.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate(
                        "AI Deepfake Sparks International Celebrity Scandal",
                      )
                    : "AI Deepfake Sparks International Celebrity Scandal"
                }
                to="/long1"
              >
                Harrington denies all allegations.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate(
                        "AI-Generated Hoax Leads to Mass Panic at Music Festival",
                      )
                    : "AI-Generated Hoax Leads to Mass Panic at Music Festival"
                }
                to="/long2"
              >
                Security concerns surrounding fake messages of unknown origin
                spreading across the world.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate(
                        "AI-Created Art Auction Causes Uproar in the Art World",
                      )
                    : "AI-Created Art Auction Causes Uproar in the Art World"
                }
                to="/long4"
              >
                Art world disrupted, and questions arise about the value of
                authenticity.
              </ListItem>
              <ListItem
                title={
                  obfuscated
                    ? obfuscate(
                        "AI-Generated Fashion Trends Cause Market Turmoil",
                      )
                    : "AI-Generated Fashion Trends Cause Market Turmoil"
                }
                to="/long3"
              >
                Economies at risk as continued false media postings threaten to
                dominate news cycle.
              </ListItem>
              <ListItem
                title={
                  obfuscated ? obfuscate("Odds and ends") : "Odds and ends"
                }
                to="/odds"
              >
                Miscellaneous thoughts from the dungeon masters.
              </ListItem>
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>
        <NavigationMenu.Item>
          <Link className="NavigationMenuLink" to="/account">
            Account
          </Link>
        </NavigationMenu.Item>
        {player && (
          <NavigationMenu.Item>
            <Link className="NavigationMenuLink" to="/game">
              Enter game
            </Link>
          </NavigationMenu.Item>
        )}
        <NavigationMenu.Item>
          <Link className="NavigationMenuLink" to="/leaderboard">
            Leaderboard
          </Link>
        </NavigationMenu.Item>
        <NavigationMenu.Item>
          <Link className="NavigationMenuLink" to="/issues">
            Known issues
          </Link>
        </NavigationMenu.Item>
        <NavigationMenu.Indicator className="NavigationMenuIndicator">
          <div className="Arrow" />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>

      <div className="ViewportPosition">
        <NavigationMenu.Viewport className="NavigationMenuViewport" />
      </div>
    </NavigationMenu.Root>
  );
};

export { Navbar };
