export enum EVENTS_NAME {
  currentSceneReady = "current-scene-ready",
  unobfuscateText = "unobfuscate-text",
  chestLoot = "chest-loot",
  attack = "attack",
  gameEnd = "game-end",
  zoneEnter = "zone-enter",
  showStoryline = "show-story-line",
  showChallenge = "show-challenge",
  clearGameUi = "clear-game-ui",
  music = "music",
  tookDamage = "took-damage",
}

export enum GameStatus {
  WIN,
  LOSE,
}
