import { Box, Flex, Section } from "@radix-ui/themes";
import { Navbar } from "../components/Navbar";
import { Countdown } from "../components/Countdown.tsx";
import { Faction } from "../components/Faction.tsx";
// import ChatDialog from "../components/ChatDialog.tsx";

function Issues() {
  return (
    <Box>
      <Navbar />
      <Countdown />
      <Faction />
      {/* <ChatDialog /> */}
      <Flex justify={"center"}>
        <Box width={"1000px"}>
          <Section>
            <Box
              style={{
                backgroundColor: "black",
                padding: "20px",
              }}
            >
              <Box
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                Known issues:
                <ul>
                  <li>
                    localstorage removes challenges you've answered and doesn't
                    add them back
                  </li>
                  <li>Global message, Faction message</li>
                  <li>Add win condition</li>
                </ul>
              </Box>
            </Box>
          </Section>
        </Box>
      </Flex>
    </Box>
  );
}

export default Issues;
