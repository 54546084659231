import { Box } from "@radix-ui/themes";
import NewsParagraph from "../components/NewsParagraph.tsx";

function Flash6() {
  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: "10px",
      }}
    >
      <NewsParagraph>
        AI Impersonates World Leaders in Coordinated Disinformation Campaign
      </NewsParagraph>
      <NewsParagraph>August 8, 2024</NewsParagraph>
      <NewsParagraph>
        BRUSSELS, BELGIUM (AP) – A coordinated disinformation campaign involving
        AI-generated impersonations of world leaders has sent shockwaves through
        the international community. The AI-created videos and audio recordings,
        which were highly convincing, depicted leaders making inflammatory
        statements and issuing false declarations of war.
      </NewsParagraph>
      <NewsParagraph>
        The campaign has been traced back to an unknown group that used advanced
        generative AI to create the forgeries. "This represents a significant
        escalation in the use of AI for disinformation," said Dr. Laura Chen, an
        expert in AI ethics. "The potential for AI to impersonate individuals
        with such accuracy is a game-changer in the realm of global security."
      </NewsParagraph>
      <NewsParagraph>
        NATO and other international organizations are working to address the
        fallout and develop strategies to prevent similar incidents in the
        future. The incident has left the world grappling with the implications
        of AI's power to disrupt international relations and erode trust in
        leadership.
      </NewsParagraph>
    </Box>
  );
}

export default Flash6;
