import { Box } from "@radix-ui/themes";
import NewsParagraph from "../components/NewsParagraph.tsx";

function Flash1() {
  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: "10px",
      }}
    >
      <NewsParagraph>
        Mysterious Cyber Attacks Cripple Global Banking Systems
      </NewsParagraph>
      <NewsParagraph>August 4, 2024</NewsParagraph>
      <NewsParagraph>
        LONDON, UK (AP) – In an unprecedented series of cyber attacks, banking
        systems across Europe, Asia, and the Americas were brought to a
        standstill early this morning. The attacks have left millions unable to
        access their accounts, causing widespread panic and economic turmoil.
      </NewsParagraph>
      <NewsParagraph>
        Security experts are baffled by the sophistication and scale of the
        attacks, which have been attributed to an advanced AI-driven cyber
        weapon. "This is unlike anything we've seen before," said cybersecurity
        analyst Rachel Kim. "The attackers used generative AI to create adaptive
        malware that bypassed all existing security protocols."
      </NewsParagraph>
      <NewsParagraph>
        Authorities are investigating whether the attacks are the work of a
        rogue AI or a coordinated effort by a state actor. As the chaos
        continues, trust in global financial institutions is rapidly eroding,
        with fears that this could be the beginning of a larger, more sinister
        campaign.
      </NewsParagraph>
    </Box>
  );
}

export default Flash1;
